@import "src/App/variables";

.dashboard-banner {
  width: 100%;
  min-height: 190px;
  margin: 0;
  border-radius: 0;

  display: flex;

  .banner-text {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 190px;
    left: 9.43%;
    right: 9.5%;
    top: calc(50% - 72px / 2);

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 36px;

    color: #FFFFFF;
    @media screen and (max-width: $mobile-last){
      font-size: 24px;
    }
  }

  background: linear-gradient(156.95deg, rgba(0, 82, 149, 0.3) 13.4%, rgba(0, 90, 163, 0.3) 87.18%),
  url("../../../assets/images/portal_banner.svg") no-repeat,
  linear-gradient(172.88deg, #73A5CC -17.66%, #1769AB 71.2%);
}

.create-proceeding-card {
  $margin: 64px;
  margin: 40px 1.5rem 3rem;
  border: 1px solid $black-coral-3;
  box-sizing: border-box;
  border-radius: 5px;
  @media screen and (min-width: 1200px) {
    margin-top: calc(40px + #{$margin});
    margin-bottom: calc(3rem + #{$margin});
    .create-proceeding-card-media {
      margin-top: -#{$margin};
      margin-bottom: -#{$margin};
    }
  }
}

@media screen and (max-width: $mobile-last) {
  .change-button-mobile-view {
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
