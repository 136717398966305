@import "src/App/variables";
.no-results-card {
  min-height: 246px;
  .no-results-content {
    //Half of parent card min-height for IE
    margin: 123px auto;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: $black-coral-5;
  }
}