@import "src/App/variables";

.s-table {
  border-collapse: separate;
  border-spacing: 0;
  border: none;

  thead {
    display: table-header-group;

    th {
      white-space: nowrap;
      border-bottom: 2px solid $gray-lighter;
      height: 41px;
      &.normal-column {
        background: $white;
      }
    }
  }

  tbody {
    td {
      white-space: nowrap;
      height: 46px;
      min-height: 46px;
      border-bottom: 1px solid $gray-lighter;
      &.normal-column {
        background: $black-coral-0;
      }
    }

    tr {
      white-space: nowrap;
      border: none;
    }
  }

  th.sticky-first-column {
    text-align: center;
  }

  .sticky-first-column {
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding: 10px;
    position: absolute;
    top: auto;
    width: 120px;
    text-align: right;
    z-index: 1;
  }

  .sticky-last-column {
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    right: 0;
    padding: 10px;
    position: absolute;
    top: auto;
    width: 150px;
    text-align: right;
  }

  .normal-column {
    position: relative;
    padding: 10px;
    top: auto;
  }
}

.table-wrap {
  position: relative;
}

.table-scroll {
  border: none;
  margin-left: 120px;
  margin-right: 150px;
  overflow-x: auto;
  overflow-y: visible;
  background: #ffffff;
  &-right {
    margin-left: 0;
  }
}

.scrollable {
  margin-right: 150px;
  overflow-x: auto;

  .normal-column {
    &:first-child {
      padding-left: 48px;
    }

    &:last-child {
      box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
      left: auto;
      padding-right: 48px;
      right: 0;
      position: absolute;
      text-align: right;
      width: 150px;
    }
  }
}

.date-column {
  align-content: center;
}

.status-column {
  min-width: 130px;
  div:nth-child(2) {
    margin-top: 2px;
  }
}

.link-column {
  min-width: 120px;
}

.application-result-row {
  td {
    height: 80px !important;
    min-height: 80px !important;
  }

  td:first-child,
  td:last-child {
    padding-top: 28px;
  }
}
