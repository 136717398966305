@import "src/App/variables";

.icon-col {
  padding-top: 10px;
}

.detail-info {
  .application-header-row {
    margin-right: 16px;

    #continueWithSavedDraft {
      margin-left: 16px;
    }

    &.renewal-row {
      .renewal-notes {
        margin-left: 48px;
        max-width: 50%;
        font-size: 14px;
        line-height: 24px;
        color: $base-text-color;

        .renewal-notes-header {
          font-weight: bold;
          margin-bottom: 8px;
        }
      }

      .renewal-button {
        margin: auto 0 auto auto;
      }
    }
  }


  .detail-col {
    .col-header.row {
      padding-left: 48px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: $base-text-color;
      border-top: 1px solid $black-coral-1;
      height: 48px;
      line-height: 48px;
    }

    .row {
      height: auto;
      min-height: 48px;
      margin: 0;
      border-bottom: 1px solid $black-coral-1;

      .col {
        margin-top: auto;
        margin-bottom: auto;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 150%;

        &:first-of-type {
          font-weight: bold;
          text-align: right;
          color: $black-coral-10;
        }

        &:nth-of-type(2) {
          color: $base-text-color;
        }
      }
    }

    .link-button {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: $tablet-last) {

  .detail-info {
    .detail-col {
      .col-header.row {
        padding-left: 15px;
        margin-top: 24px;
        padding-bottom: 12px;
        border-top: 0;
      }

      .row {
        border-bottom: 0;

        &:first-of-type {
          border-bottom: 1px solid $black-coral-1;
        }

        .col {
          font-style: normal;

          &:first-of-type {
            margin-top: 12px;
            font-weight: normal;
            color: $base-text-color;
            text-align: left;
            margin-bottom: 8px;
          }

          &:nth-of-type(2) {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 16px
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-last) {
  .detail-info .application-header-row.renewal-row {
    margin: 16px;

    .renewal-button {
      order: 1;
      margin: auto auto 16px auto;
      width: 100%;
      text-align: center;
    }

    .renewal-notes {
      order: 2;
      max-width: 100%;
      margin: 0;
    }
  }
}
