@import "src/App/variables";

.search-application {
  .button {
    height: 39px;
    margin: 10px;
    max-width: 210px;
  }

  .card {
    border: 1px solid $black-coral-2;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 100px;
  }

  .footer {
    min-width: 100%;
    border-top: 1px solid $gray;
  }
  .gp-filter-checkbox {
    padding-left: 1.4rem;
  }
}
