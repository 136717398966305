@import "src/App/variables";

.add-education-info.modal-dialog {
  width: 100%;
  font-family: Roboto, sans-serif;

  .modal-content {
    width: 100%;
    padding: 12px;

    .add-education-section {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;


      .add-education-subtitle {
        font-size: larger;
        margin-bottom: 24px;
      }
    }
  }
}