.contact-modal.modal-dialog{
  @media (min-width: 576px) {
    max-width: 719px;
  }
}

.contact-modal-checkbox-group {
  .checkbox-item {
    min-height: 2.3rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @media (max-width: 576px) {
      min-height: 2rem;
    }
  }
}

.contact-modal-hours {
  @media (max-width: 576px) {
    input {
      font-size: 14px;
    }
  } 
}

.d-flex-with-gap {
  align-items: center;
  display: flex;
  gap: 3px;
}

.digital-platform-info-icon {
  background: url("../../../../../assets/images/information.svg") no-repeat center;
  background-size: 16px;
  box-sizing: content-box;
  cursor: pointer;
  height: 16px;
  padding: 0 0 8px 8px;
  width: 16px;
}


