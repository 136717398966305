@import "src/App/variables";

.personal-data {
  max-width: 100%;
  width: 700px;

  label {
    width: 160px;
    text-align: right !important;
    padding-right: 10px;
    display: block !important;;

    & + div, & + input {
      width: calc(100% - 160px) !important;
    }
  }

  .personal-code-country-select {
    max-width: 425px;
  }

  .search-personal-data {
    width: 190px;
  }

  .personal-code-input {
    max-width: 210px;
    margin-right: 25px;
  }

  .personal-code-error {
    max-width: 210px;
  }

  div.checkbox-form-group {
    margin-right: calc(100% - 390px);

    label {
      text-align: left !important;
    }

    @media(max-width: $tablet-last) {
      margin-right: 0;
      margin-left: 150px;
      justify-content: flex-start;
    }
  }
}

.card-holder {
  @media(min-width: $desktop-first) {
    margin-left: 160px;
  }
  margin-top: 24px;
  max-width: 670px;
}
