@import "src/App/variables";

.application-container {
  padding-top: 40px;

  .proceeding-primary-button {
    max-height: initial;
    margin-top: auto;
  }

  .application-type-selection {
    min-height: 70px;
    margin-bottom: 24px;
  }

  .application-type-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      img {
        height: 139px;
        margin-top: 8px;
      }

      h3 {
        margin-top: 27px !important;
      }
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
      margin-bottom: 48px !important;

      > div {
        text-align: center;

        img {
          display: block;
          margin: 24px auto !important;
        }

        .application-type-selection {
          margin: 0;
        }
      }

      .proceeding-primary-button {
        margin-top: 24px !important;
      }
    }
  }

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    @media screen and (max-width: $mobile-last){
      font-size: 28px;
    }
  }

  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    @media screen and (min-width: $tablet-first) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
  }

  dl {
    background: $black-coral-0;
    display: grid;
    grid-gap: 8px;
    width: auto;
    dd {
      margin-bottom: 16px;
    }
    @media screen and (min-width: $tablet-first) {
      grid-gap: 20px;
      grid-template-columns: 1fr 2fr;
      width: 690px;
      dt {
        justify-self: end;
        text-align: end;
      }
      dd {
        margin-bottom: 0;
      }
    }
  }

  dl.services-grid {
    grid-template-columns: 1.25fr 1.75fr;
  }

  dt {
    font-weight: normal;
  }

  dd {
    font-weight: 500;
    margin: 0;

    .payment-status-wrapper {
      margin-bottom: 16px;
      .link-button{
        padding-top: 0;
        padding-bottom: 0;
      }
      .btn-secondary{
        max-height: initial;
      }
    }
  }

  dd.negative-margin {
    margin-top: -15px;
  }

  textarea {
    border-color: $black-coral-3;
    border-radius: 5px;
  }

  .quick-links {
    grid-auto-columns: 200px 150px;
    grid-template-rows: repeat(3, 1fr);
  }

  .font-size-18 {
    font-size: 18px;
  }

  .create-application-selection-card {
    width: 98%;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .application-footer-hr {
    border-color: $black-coral-10;
  }

  .link {
    color: $sapphire-blue-10;
    text-decoration: underline;
  }

  .application-title {
    @media screen and (max-width: $mobile-last) {
      display: none;
    }
  }

  .disabled-anchor {
    color: $black-coral-10;
    pointer-events: none;
    cursor: default;
  }

  .application-footer-buttons {
    white-space: nowrap;

    #saveDraft {
      margin-left: auto !important;
    }

    #forward {
      margin-left: 0 !important;
    }

    @media screen and (max-width: $mobile-last) {
      display: flex !important;
      flex-direction: column !important;
      align-items: center;
      justify-content: center;
      #saveDraft {
        margin-top: 24px !important;
        margin-left: 0 !important;
        order: 1;
      }

      #discard,
      #back {
        margin-top: 26px !important;
        order: 2;
      }

      #forward {
        margin-top: 16px !important;
        order: 3;
      }
    }
  }

  .info-card {
    min-height: 72px;
  }

  .card {
    background: $white;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;
    border-radius: 5px;
    color: $base-text-color;
  }

  .submission-info-container {
    margin-left: 126px !important;
    width: 700px;
    max-width: calc(100% - 126px);

    @media screen and (max-width: $tablet-last) {
      margin-left: 0 !important;
      max-width: 100%;
      width: auto;
    }
  }
}

.application-personal-data-label {
  margin-right: 12px;
  display: inline-block !important;
  width: 110px;
  text-align: right;
}

.education-content {
  max-width: 100%;
  width: 700px;

  #newQuery {
    margin-right: 45px;
    margin-left: auto;
  }

  label {
    width: 160px;
    text-align: right !important;
    padding-right: 10px;
    display: block !important;

    & + div,
    & + input {
      width: calc(100% - 160px) !important;
    }
  }

  .application-fields {
    margin-left: 160px;
  }

  @media screen and (max-width: $mobile-last) {
    label {
      text-align: left !important;
      margin-left: 0 !important;
      margin-right: 10px !important;
      margin-bottom: 0 !important;

      & + div,
      & + input {
        width: 100% !important;
      }
    }
    .application-fields {
      margin-left: 0;
    }
  }

  .education-view-card {
    width: 672px;
    max-width: 100%;

    .education-info-view-row {
      margin: 16px 0 0 0;

      &.first-row {
        margin-top: 24px;
      }

      &.last-row {
        margin-bottom: 24px;
      }
    }
  }
}

.application-detail {
  dl, h3 {
    width: auto;
    max-width: 690px;
  }
}

.application-overview-card-container {
  width: 700px;
  max-width: 100%;

  h5 {
    margin-top: 32px;
  }
}

@media screen and (max-width: $tablet-last) {
  .education-content {
    width: auto;
    .education-view-card {
      width: auto;
    }
  }

  .application-overview-card-container {
    width: auto;
  }
}

.pointer:hover {
  cursor: pointer;
}

.modification-card {
  margin-top: 15px;
  padding: 20px;

  &.large-margin {
    margin-top: 40px;
  }
}
