@import "src/App/variables";

.tooltip {

  &.tooltip-inner {
    background-color: $sapphire-blue-10
  }

  &.show {
    opacity: 1;
  }
}

.fade {
  transition: none;
}
