@import "src/App/variables";

#invalidation-reason-modal {

  width: 100%;
  max-width: 560px;
  font-family: Roboto, sans-serif;

  .modal-content {
    width: 100%;
    padding: 24px;

    .modal-body {
      dl {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 160px auto;
      }

      dt {
        font-weight: normal;
        justify-self: end;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: $black-coral-18;
        vertical-align: middle;

        label {
          margin: 0;
          vertical-align: middle;
        }
      }

      dd {
        margin: 0;
      }
    }
  }
}


