@import "../../variables";

.custom-radio {
  &.custom-control {
    padding-left: 30px;
    min-height: 1.5rem;
    line-height: 1.5rem;
    margin: 3px;
  }
  label {
    width: auto !important;
    padding: 0 !important;
  }

  .custom-control-label::before {
    width: 20px;
    height: 20px;
    top: 2px;
    left: -30px;
    background: $white;
    border-color: $gray-light;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: $white;
    border-color: $gray-light;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background: $sapphire-blue-10 !important;
    width: 10px;
    height: 10px;
    top: 7px;
    left: -25px;
    border-radius: 50%;
  }
}