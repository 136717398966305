@import "src/App/variables";

.applicant-container {
  .quick-links {
    grid-template-columns: repeat(3, 250px);
    grid-template-rows: repeat(4, 30px);
  }

  @media (max-width: $tablet-last) {
    .quick-links {
      grid-template-columns: repeat(3, 240px);
    }

    .applicant-details-card-margin {
      margin-bottom: 24px;
    }
  }

  .applicant-changes-form {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 40px;
    grid-template-columns: repeat(2, min-content);

    .form-group {
      align-self: start;
      display: grid;
      grid-column-gap: 8px;
      grid-row-gap: 12px;
      grid-template-columns: min-content auto;
      margin: 0;

      label {
        display: inline-block;
        text-align: right;
        white-space: nowrap;
      }
      label.required::after {
        content: " *";
        color: red;
      } 
    }

    @media (max-width: $tablet-last) {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
      .form-group {
        width: 100%;

        label {
          width: 140px !important;
        }

        .react-datepicker-wrapper {
          width: 100%;
        }

        span {
          margin: 0 8px;
        }
      }
    }
  }


  .external-decisions-upload {
    grid-template-columns: none;
  }

  .attachment-download-link {
    color: $sapphire-blue-10;
  }

  .applicant-details-container {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.8em;
    grid-template-columns: repeat(2, 40%);
    grid-template-rows: repeat(5, min-content);

    .form-group {
      align-self: end;
      display: grid;
      grid-column-gap: 12px;
      grid-row-gap: 12px;
      grid-template-columns: min-content auto;
      margin: 0;

      .form-control {
        width: 100%
      }

      label {
        width: 200px;
        display: inline-block;
        text-align: right;
        white-space: nowrap;
      }
    }

    @media (max-width: $desktop-first) {
      display: flex;
      flex-direction: column;
      .form-group {
        width: 100%;

        label {
          width: 200px !important;
        }

        .react-datepicker-wrapper {
          width: 100%;
        }

        span {
          margin: 0 8px;
        }
      }
    }
  }

  .health-care-professional-details-container {
    dl {
      background: $white;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 250px 2fr;
      width: 690px;

      @media screen and (max-width: 768px) {
        width: auto;
      }
    }

    dt {
      font-weight: normal;
      justify-self: end;
    }

    dd {
      font-weight: 500;
      margin: 0;

    }

    .applications {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 20px;
      @media (max-width: $desktop-first) {
        grid-template-columns: auto;
      }
    }
  }

  .migrated-education-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 200px auto;
  }

  .migrated-education-grid-edit {
    position: absolute;
    right:10%;
    top:2%;
  }

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
  }

  h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
  }

  .card {
    border-radius: 4px;
    border: 1px solid $black-coral-2;

    hr {
      border: 1px solid $black-coral-2;
    }

    .subtitle {
      font-size: 14px;
      color: $black-coral-10;
    }
  }

  .user-applications-table {
    .short-column {
      width: 80px;
    }

    .large-column {
      width: 180px;
    }

    .normal-column {
      padding-right: 48px;

      &:last-child {
        padding-right: 24px;
        text-align: right;
        width: 150px;
      }
    }

    @media (max-width: $desktop-first) {
      .normal-column {
        padding-right: 24px;
        padding-left: 0;
      }

      .speciality-column {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .large-column {
        width: 145px;
      }
    }
  }

  .file-text-gray {
    flex: unset;
    max-width: fit-content;
  }
}

.prescription-power-applicant-form {
  grid-template-columns:auto !important;
  > div {
    input {
      width: 100% !important;
    }
  }  
}
