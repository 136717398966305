@import "src/App/variables";

.tabs {
  &-container {
    margin-bottom: 10px;

    .nav-tabs {
      margin-bottom: -1px;
      position: relative;
      z-index: 1;
    }
    .nav-link.active {
      border-color: $black-coral-2 $black-coral-2 $white;
      color: $sapphire-blue-10;
      outline: none;
    }
    .nav-link:hover {
      border-color: transparent;
      color: $sapphire-blue-10;
    }
    .nav-link:focus {
      outline-color: $sapphire-blue-10;
      outline-width: 2px;
    }
    & ~ .tabs-card {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}