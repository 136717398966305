@import "src/App/variables";

.public-healthcare-employees-details {
  .title {
    @media screen and (max-width: $mobile-last) {
      font-size: 28px;
    }
  }

  .section {
    .title {
      @media screen and (max-width: $mobile-last) {
        font-size: 24px;
      }
    }

    dt {
      @media screen and (max-width: $mobile-last) {
        font-size: 14px;
      }
    }

    dd {
      @media screen and (max-width: $mobile-last) {
        font-size: 16px;
        font-weight: 500;
      }
    }

  }
  .no-record-of-working-block{
    max-width: 690px;
    @media screen and (max-width: $mobile-last) {
      max-width: 100%;
    }
  }
}