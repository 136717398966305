@import "src/App/variables";

#add-specialist-modal {
  max-width: 595px;

  .modal-header,
  .subtitle {
    margin-left: 8px;
  }

  .modal-footer {
    margin-right: 6px;
  }

  .subtitle {
    font-family: Roboto sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
  }

  .personal-code-search-box {
    margin: 8px 8px 24px;
    padding: 32px 16px;

    background: $black-coral-0;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;

    .speciality-input-container {
      margin-bottom: 16px;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $mobile-last) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .personal-code-input {
      max-width: 175px;

      @media screen and (max-width: $mobile-last) {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }

    .speciality-select {
      width: 320px;

      @media screen and (max-width: $mobile-last) {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}