@import "src/App/variables";
// Required
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

@import "../../../../node_modules/bootstrap/scss/popover";

.popover-header {
  font-family: Roboto, sans-serif;
  font-style: normal;
  border-bottom: none !important;
}

