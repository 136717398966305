@import "src/App/variables";

.personal-detail-modal-body {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8em;
  grid-template-columns: repeat(2, 49%);
  grid-template-rows: repeat(5, min-content);

  .form-group {
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: min-content auto;
    margin: 0;

    .form-control {
      width: 100%
    }

    label {
      width: 150px;
      display: inline-block;
      text-align: right;
    }
  }

  @media (max-width: $desktop-first) {
    display: flex;
    flex-direction: column;

    .form-group {
      width: 100%;

      label {
        width: 150px !important;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      span {
        margin: 0 8px;
      }
    }
  }
}

.personal-detail-modal-foreigner-label {
  text-align: unset !important;
  width: auto !important;
}

.personal-detail-modal-status-field {
  >div {
    > :last-child {
      display: none;
    }
  }
}

.personal-detail-modal-disable-input {
  border-color: transparent !important;
  background-image: unset !important;
}