@import "src/App/variables";

.certificate-request-container {
  padding-top: 40px;

  .card {
    border-color: $black-coral-3;
  }
}

.certificate-request-form,
.gp-contact-information-form {
  .form-group {
    &.required > label:after {
      font-size: 14px;
      content: "*";
      margin-top: 3px;
      margin-left: 2px;
      color: $jasper-10;
    }
    textarea {
      min-height: 4 * 1rem;
    }
    &.required > span:first-child::after {
      font-size: 14px;
      content: "*";
      margin-top: 3px;
      margin-left: 2px;
      color: $jasper-10;
    }
  }
}

.certificate-issue-address {
  label {
    width: 168px !important;
    text-align: right;
    align-items: end;
    line-height: 1.2;
    padding-top: 0px !important;
  }
  label::after {
    margin-bottom: 2px;
  }

  .address-input-text,
  .address-input-additional-info {
    display: flex;
    flex-wrap: wrap;
    span {
      font-size: 16px;
      margin-right: 6px;
    }
    button {
      padding: 0px;
      font-size: 16px;
    }
  }
}

.address-input-additional-info {
  margin-left: 175px;
}

@media screen and (max-width: $mobile-last) {
  .address-input-additional-info {
    margin-left: 0;
  }
}

.ads-address-input-container {
  label::after {
    font-size: 14px;
    content: "*";
    margin-top: 3px;
    margin-left: 2px;
    color: #D73E3E;
    margin-bottom: 2px;
  }
}

.ads-address-zip-code {
  margin-bottom: 0px;
}