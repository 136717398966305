@import "src/App/variables";

.item {
  height: 99px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  .item-content {

    img {
      height: 24px;
      width: 24px;
    }
  }

  .item-text {
    font-size: 14px;
    line-height: 26px;

    span {
      vertical-align: middle;
      display: inline-block;
    }

    @media screen and (max-width: $tablet-last) {
      span {
        display: block;
      }
    }
  }

  @media screen and (max-width: $tablet-last) {
    .item-content {
      padding: 0;
      float: none;
    }
  }
}

.item:hover {
  background-color: $sapphire-blue-12;
}
