.representative-view-modal-container {
  > :last-child {
    margin-bottom: 20px;
  }
}

.representative-view-modal-item-container {
  display: flex;
  margin-bottom: 12px;
  font-size: 18px;

  > :last-child {
    font-weight: 500
  }
}

.representative-view-modal-label {
  display: flex;
  flex-direction: column;
  min-width: 160px;
  align-items: flex-end;
  margin-right: 12px;
  justify-content: center;
}

.representative-view-modal-info-text {
  font-size: 18px;
  margin-bottom: 4px;
}