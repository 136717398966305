@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext');

@import "src/App/variables";

@import "Component/Modal/Modal";
@import "Component/Popover/Popover";
@import "Component/Card/Card";

$theme-colors: (
        primary: $sapphire-blue-10
);

.App {
  height: 100%;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;

  h1, .h1 {
    font-size: 32px;
    line-height: 48px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 36px;
  }

  h4, .h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }

  .fw-300 {
    font-weight: 300 !important;
  }

  .fw-normal {
    font-weight: normal !important;
  }

  .fw-500 {
    font-weight: 500 !important;
  }

  .fw-700 {
    font-weight: 700 !important;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .fs-16 {
    font-size: 16px !important;
  }

  .lh-131 {
    line-height: 131% !important;
  }

  .lh-150 {
    line-height: 150% !important;
  }

  input {
    border: 1px solid $black-coral-6;
  }

  input:focus, input.form-control:focus {
    outline: none 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: 1px solid $sapphire-blue-10;
  }

  // Hide number input spinner
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.was-validated input[type=time].form-control:invalid,
input[type=time].form-control.is-invalid {
  background: none;
  padding-right: .75rem
}

.cursor-pointer {
  cursor: pointer;
}

.mw-270 {
  min-width: 270px !important;
}

.mw-600 {
  min-width: 600px !important;
}

@media screen and (max-width: $tablet-last) {
  .d-md-table-header-group {
    display: table-header-group !important;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.font-size-14 {
  font-size: 14px;
}