@import "src/App/variables";

.footer {
  width: 100%;

  .footer-top {
    min-height: 83%;
    background-color: $footer-top;

    .icon {
      position: relative;
    }

    .communication-icon {
      left: -32px;
    }

    .quick-links-icon {
      left: -31px;
    }

    .contact-info-icon {
      left: -30px;
    }

    .footer-cell {
      margin-top: 80px;
      margin-bottom: 70px;


      .content {
        margin-left: 60px;
        line-height: 1.625rem;
      }

      @media screen and (max-width: $tablet-last) {
        .footer-title {
          text-align: center;
        }

        .quick-links {
          line-height: 36px;
        }

        .footer-cell {
          display: flex;
          justify-content: center;
        }

        .content {
          text-align: center;
          margin-left: 0;
          margin-bottom: 14px;
          min-width: 75%;
        }
      }

      .line-separator {
        border-right: 1px solid $black-coral-10;
      }

      @media screen and (max-width: $tablet-last) {
        .line-separator {
          border-right: none;
          border-bottom: 1px solid $black-coral-10;
        }
      }
    }
  }

  @media screen and (max-width: $tablet-last) {
    .footer-cell:first-child {
      margin-top: 40px;
      margin-bottom: 14px;
    }

    .footer-cell:not(:first-child) {
      margin-top: 0;
      margin-bottom: 14px;
    }
  }

  .link {
    text-decoration: underline;
    color: $white;
  }

  .footer-bottom {
    height: 17%;
    background-color: $footer-bottom;
    font-size: 0.875rem;
    position: relative;

    .footer-bottom-links {
      display: flex;

      .first-group {
        display: flex;
      }
    }

    .footer-bottom__el-logo{
      position: absolute;
      width: 180px;
      right: 16px;
      bottom: 0;
    }

    @media screen and (max-width: $mobile-last) {
      height: 184px;
      .footer-bottom-links {
        display: block;


        .first-group {
          margin-top: 30px;
        }

        .second-group {
          margin-top: 15px;
          text-align: center;
        }
      }
      .footer-bottom__el-logo {
        width: 120px;
      }
      }

    .footer-bottom-link-separator {
      font-size: 4px;
      margin-right: 20px;
      margin-left: 20px;
      margin-top: 10px;
      color: $black-coral-10;
    }
  }
}
