@import "src/App/variables";

.logout-button {
  text-decoration: underline;
  margin-top: 15px;
  color: $sapphire-blue-10 !important;
}

.logout-button-white {
  text-decoration: underline;
  margin-top: 15px;
  color: $white !important;
}

.login-button {
  padding: 10px 45px !important;
  background-color: $sapphire-blue-10 !important;
  border-radius: 20px !important;
}

@media screen and (max-width: $tablet-last) {
  .logout-button {
    color: $white;
  }
  .logout-button:hover {
    color: $black;
  }
}
