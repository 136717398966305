.single-select {
  .css-1hwfws3 {
    display: inline-flex;
    overflow: inherit;
  }

  .css-26l3qy-menu {
    z-index: 2;
  }

  .form-control {
    display: flex !important;
    padding: 0;

    &.is-valid, &.is-invalid {
      padding-left: 0;
    }
  }
}
