@import "src/App/variables";

.loader {
  margin: auto;
  display: block;

  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-animation {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 54px;
      height: 54px;
      margin: 8px;
      border: 6px solid $white;
      border-radius: 50%;
      animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $sapphire-blue-10 transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.position-absolute, &.position-fixed {
    z-index: 1100;
    top: 0;
    left: 0;
    background-color: $gray-backdrop;

    .loading-animation {
      margin: auto;
      z-index: 1101;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &.position-absolute {
    height: 100%;
    width: 100%;
  }
  &.position-fixed {
    height: 100%;
    width: 100%;
  }
}
