@import "src/App/variables";

$min-width: 1400px;
.filters {
  &-container {
    .nav-tabs {
      position: relative;
      z-index: 1;
      margin-bottom: -1px;
      .nav-link.active {
        border-color: $black-coral-2 $black-coral-2 $white;
        color: $sapphire-blue-10;
        outline: none;
      }
      .nav-link:hover{
        color: $sapphire-blue-10;
        border-color: transparent;
      }
      .nav-link:focus{
        outline-color: $sapphire-blue-10;
        outline-width: 2px;
      }
      & ~ .filters-card {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
  &-card {
    padding: 24px;
  }
}

.filter {
  &__col {
    flex: 0 0 100%;
    .form-group {
      width: 100%;
      &__label {
        padding-right: 8px !important;
        float: left;
        min-width: 164px;
      }
      &__field {
        width: 100%;
        position: relative;
        clear: both;
        .info-icon {
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -16px;
          width: 16px;
          height: 16px;
          padding: 8px;
          background: url("../../../assets/images/information.svg") no-repeat center;
          background-size: 16px;
          box-sizing: content-box;
        }
      }
    }
    @media screen and (min-width: $tablet-first) {
      .form-group {
        display: table-row;
        &__label, &__field {
          display: table-cell;
          vertical-align: middle;
          padding: 8px 0;
        }
        &__label {
          float: none;
          white-space: nowrap;
          text-align: right;
        }
      }
      .custom-control.custom-checkbox {
        min-height: 54px;
        display: flex !important;
        align-items: center;
      }
    }

    @media screen and (min-width: $min-width) {
      flex: 1 0 0;
      .form-group {
        &__label {
          min-width: 0;
        }
        &__field {
          width: 275px;
        }
      }
    }
  }
}