@import "src/App/variables";

.stepper {
  @media screen and (min-width: $desktop-first) {
    display: none;
  }
  font-style: normal;
  font-weight: normal;

  .stepper-step-name {
    color: $sapphire-blue-10;
    font-size: 20px;
    line-height: 30px;
  }

  .stepper-text {
    margin-left: -20px;
  }

  .stepper-next-step {
    color: $base-text-color;
    font-size: 14px;
    line-height: 21px;
  }

  .stepper-icon-text {
    position: relative;
    left: -40px;
    top: 3px;
    color: $sapphire-blue-10;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: bold;
  }
}
