@import "src/App/variables";
// Required
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

@import "../../../../node_modules/bootstrap/scss/modal";

.modal-header {
  font-family: Roboto, sans-serif;
  font-style: normal;
  border-bottom: none !important;
  justify-content: left !important;
}

.modal-body {
  text-align: left;
  font-size: 16px;
}

.modal-title {
  font-weight: normal;
  font-size: 24px;
}

.modal-footer {
  border-top: none !important;
}
