$sapphire-blue-0: #E7F0F6;
$sapphire-blue-1: #D0E1EE;
$sapphire-blue-10: #005AA3;
$sapphire-blue-12: #004A86;
$sapphire-blue-13: #004277;
$sapphire-blue-14: #003A68;
$white: #FFFFFF;
$black: #000000;
$gray: #5D6071;

$footer-top: #34394C;
$footer-bottom: #262B3B;
$content-view: #E5E5E5;
$app-background: #f2f3f3;
$gray-lighter: #DBDFE2;
$gray-light: #8F91A8;
$gray-backdrop : rgba(219, 223, 226, .5);
$black-coral: #F0F0F2;
$black-coral-0: #FDFDFD;
$black-coral-1: #E1E2E5;
$black-coral-2: #D2D3D8;
$black-coral-3: #C4C5CB;
$black-coral-5: #A6A8B1;
$black-coral-6: #9799A4;
$black-coral-8: #7A7C8A;
$black-coral-10: #5D6071;
$black-coral-11: #555867;
$black-coral-15: #33353E;
$black-coral-18: #1A1B1F;
$black-coral-20: #09090B;
$base-text-color: #1A1B1F;

$jasper-0: #FBEDED;
$jasper-1: #F7DBDB;
$jasper-3: #F0B8B8;
$jasper-6: #E58484;
$jasper-10: #D73E3E;
$jasper-12: #B03333;

$sea-green-0: #ECF4EF;
$sea-green-2: #C6DED0;
$sea-green-10: #308653;
$sea-green-12: #266b42;

$dark-tangerine-0: #FFF8E9;
$dark-tangerine-10: #FFB511;
$dark-tangerine-15: #8C630A;

$orange-10: #FF8000;

$input-field-valid: #28a745;
$input-field-invalid: #dc3545;

.sea-green-10 {
  color: $sea-green-10;
}

//	Breakpoints
$mobile-last: 767px;
$tablet-first: 768px;
$tablet-last: 991px;
$desktop-first: 992px;
$menu-breakpoint: 1100px
