@import "src/App/variables";

.applicant-contact-info-edit-modal.modal-dialog {
  width: 100%;
  min-width: $tablet-first;
  font-family: Roboto, sans-serif;

  .modal-content {
    width: 100%;
    padding: 24px 24px 32px 24px;

    .main-column {
      margin: 0 !important;

      label {
        width: 100px !important;
        min-width: 100px;
        max-width: 100px;
      }

      .zip-code-container, .manual-address-toggle-text-container {
        padding-top: 6px;
        margin-left: 110px;
        margin-bottom: 0;
      }

      .query-box {
        margin-left: 110px;
      }
    }
  }
}
