@import "src/App/variables";

.role-dropdown-container {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  max-width: 270px;
  margin-top: 24px;

  .role-header {
    color: $black-coral-10;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  }

  .dropdown-menu {
    max-width: 270px;

    .dropdown-header {
      font-size: 14px;
      line-height: 19px;
      color: $black-coral-11;
      padding: 0 0 8px 8px;
    }

    .dropdown-item {
      white-space: normal;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 16px;
      width: 100%;

      border: none !important;
      outline: none !important;
    }

    .dropdown-item:not(:last-child) {
      margin: 8px 0;
    }
  }
}

.name-container {
  margin-top: 24px;
  max-width: 270px;
  .name-header {
    color: $black-coral-10;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  }

  .name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $base-text-color;
  }
}

@media screen and (max-width: 1200px) {
  .role-dropdown-container {

    .role-header {
      color: $white;
    }
  }
}