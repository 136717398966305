@import "../../../../variables";

.insurance-table-header{
  align-items: center;
  border-bottom: 1px solid #E1E2E5;
  display: flex;
  margin: 0 0 5px;
  min-height: 48px;

  .title {
    font-size: 14px;
    font-weight: bold;
  }
}

.insurance-table {
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.no-data-text {
  font-style: italic;
  margin-top: 16px;
  text-align: center;
}

.insurance-info-icon {
  margin-bottom: 2px;
  margin-left: 10px;
}