@import "src/App/variables";

#service-deletion-confirmation-modal {
  max-width: 560px;

  .modal-content {
    padding: 24px;

    .modal-body {
      padding: 0;
      margin-bottom: 48px;

      h3 {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        color: $base-text-color;
        margin: 0;
      }
    }

    .modal-footer {
      padding: 0;
    }
  }
}