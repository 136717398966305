@import "../../../../variables";

.table-footer {
  min-width: 100%;
  font-size: 14px;
  color: $gray;
  align-content: center;
  justify-content: center;
  min-height: 50px !important;
  border: none;

  display: flex;
  align-items: center;

  .pagination-wrapper {
    text-align: center;
    flex: 1;
  }

  .page-count-wrapper {
    margin: auto 16px auto auto;
    flex: 0;
    display: none;
    white-space: nowrap;
    @media screen and (min-width: $tablet-first) {
      display: block;
    }
  }
}

.nav-button-group {
  align-content: center;
  justify-content: center;
  display: flex;
}

.nav-button-arrow {
  height: 26px !important;
  width: 26px !important;
  border-radius: 13px !important;
  border: none;
  background: none !important;
  fill: $gray;

  &.disable {
    filter: brightness(300%) contrast(76%);
  }

  &:hover {
    background-color: $gray-lighter !important;
    filter: none;
  }
}

.nav-button-page {
  height: 26px !important;
  min-width: 26px !important;
  margin: 5px;
  border-radius: 13px !important;
  border: none;
  background: none;
  font-size: 12px;
  color: $gray;

  &.current {
    background: #005aa3;
    color: white;
  }

  &:hover {
    background-color: $sapphire-blue-12 !important;
    color: $white;
  }
}

.per-page {
  color: $gray;
  text-align: right;
}

.per-page-number {
  color: $gray;
  padding: 4px;
  margin-left: 10px;
  font-size: 12px !important;
}
