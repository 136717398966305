.arrival-channel-select {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

//@media screen and (max-width: $tablet-last) {
@media screen and (max-width: 991px) {
  .arrival-channel-select {
    display: block;
  }
}
