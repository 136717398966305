@import "src/App/variables";

.global-sidemenu {
  .global-sidemenu-list {
    list-style: none;
    padding-left: 0;

    li {
      border: 1px solid $sapphire-blue-14;
      .item-text {
        > div {
          padding-top: 8px;
          white-space: pre-wrap;
          line-height: 16px;
        }
      }
    }

    li:not(:last-child) {
      border-bottom: 0;
    }
  }

  .bottom-container {
    display: none;
  }

  .item-active > .item {
    background-color: $sapphire-blue-12;
  }

  @media screen and (max-width: $menu-breakpoint) {
    .bottom-container {
      height: 90px;
      justify-content: center;
      width: 100%;
      display: flex;
      bottom: 0;
      color: $white;

      .bottom-container-separator {
        width: 2px;
        height: 30px;
        background: $white;

        border: 1px solid $gray-lighter;

        flex: none;
        align-self: center;
      }

      .bottom-container-logout-button {
        height: 100%;
        flex: none;
        order: 1;
        align-self: flex-end;
      }
    }
  }


  @media screen and (max-width: $menu-breakpoint) {
    .bottom-container {
      flex-wrap: wrap;

      .bottom-container-separator.order-1 {
        flex-basis: 100%;
        height: 0;
        width: 0;
        border: 0;
      }

      .bottom-container-logout-button {
        .align-items-center {
          justify-content: center;
        }
      }
    }
  }
}
