@import "src/App/variables";

.popover-container > .popover {
  max-width: 327px;
  height: 173px !important;
}

.dropzone-inner {
  max-width: 294px;
  padding: 16px 16px 0 16px;

  &.initial .text-top {
    border: 1px dashed $black-coral-8;
  }

  &.dragging .text-top {
    border: 1px solid $sapphire-blue-10;
    background: $sapphire-blue-0;
  }

  .text-top {
    border-radius: 2px;
    text-align: center;
    padding: 16px;
    position: relative;

    &.dragging-text {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  .text-bottom {
    margin: 8px 0;
    text-align: center;
    font-size: 12px;
  }

  .upload-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
}


@media screen and (max-width: $mobile-last) {
  .dropzone-inner {
    width: 250px;
  }
}
