@import "src/App/variables";

.date-container .dropdown > button {
  border: 1px solid $black-coral-6 !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  height: 100%;
  padding: 4px !important;
  width: 70px;
}

.date-container .dropdown-menu {
  min-width: 70px;
}

.date-container .insurance-search-date {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
