@import "src/App/variables";

#add-rooms-modal {
  max-width: 100%;
  width: 560px;

  h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    font-style: normal;
  }

  .modal-content {
    padding: 24px;

    > * {
      padding: 0;
    }

    .room-name-wrapper {
      .room-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      margin-bottom: 16px;
    }

    .modal-header {
      h4 {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .modal-body {
      margin: 32px 0 40px;

      .title-container {
        display: flex;
        justify-content: space-between;
      }

      .title-container:not(:first-child) {
        margin-top: 24px;
      }

      .info-card {
        margin-top: 8px;
        padding: 16px;
        display: grid;
        column-count: 2;
        column-gap: 10px;
        row-gap: 16px;
        grid-template-columns: 150px auto;
        align-items: center;

        @media screen and (max-width: $mobile-last) {
          grid-template-columns: auto;
          row-gap: 8px;
          column-count: 1;
        }

        .input-group {
          margin: 0 !important;
          padding: 0 !important;
          border: 1px solid $black-coral-6 !important;
          border-radius: 4px;

          &:focus, &:active, &.open {
            border: 1px solid $sapphire-blue-10 !important;
          }

          &.is-valid {
            border-color: $input-field-valid !important;
          }

          &.is-invalid {
            border-color: $input-field-invalid !important;
          }

          input {
            background-color: transparent;
            border: none !important;
            outline: none !important;

            &:focus {
              box-shadow: none !important;
              border: none !important;
              outline: none !important;
            }
          }

          img {
            padding-right: 10px;
            cursor: pointer;
          }
        }

        label {
          text-align: right;
          margin: 0;
        }
      }
    }
  }
}