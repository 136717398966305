@import "src/App/variables";

.wizard-progress-bar {
  @media screen and (max-width: $tablet-last) {
    display: none;
  }
  .nav-item.complete {
    max-width: fit-content;
  }

  /* Style imported from mta-react-common-visuals  */
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }

  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
    border: none;
    outline: 0;
  }

  .nav-link.disabled {
    color: #6c757d;
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }

  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }

  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #dbdfe2;
    border-color: #dee2e6 #dee2e6 #dbdfe2;
  }

  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .nav-tabs.form-nav .nav-link.complete::after, .nav-tabs.form-nav.form-steps .nav-item.complete .nav-link::after, .nav-tabs.form-nav.form-steps .nav-item.done .nav-link::after {
    color: #308653;
    content: "\e909";
    font-size: 10px;
  }

  .nav-tabs.form-nav .nav-link.error::after, .nav-tabs.form-nav.form-steps .nav-item.error .nav-link::after {
    color: #ff7373;
    content: "\e91b";
  }

  .nav-tabs.form-nav .nav-link.warning::after, .nav-tabs.form-nav.form-steps .nav-item.warning .nav-link::after {
    color: #ffcf00;
    content: "\e91a";
  }

  .nav-tabs.form-nav .nav-link.locked, .nav-tabs.form-nav.form-steps .nav-item.locked .nav-link {
    color: #9d9fb4;
    cursor: not-allowed;
  }

  .nav-tabs.form-nav .nav-link.locked:focus, .nav-tabs.form-nav.form-steps .nav-item.locked .nav-link:focus, .nav-tabs.form-nav .nav-link.locked:active, .nav-tabs.form-nav.form-steps .nav-item.locked .nav-link:active {
    pointer-events: none;
  }

  .nav-tabs.form-nav .nav-link.locked::after, .nav-tabs.form-nav.form-steps .nav-item.locked .nav-link::after {
    color: #9d9fb4;
    content: "\ec7c";
  }

  .nav-tabs.form-nav {
    background-color: $black-coral;
    border-bottom: 0;
    border-radius: 5px;
    padding: 10px 0;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav {
      display: none;
    }
  }

  .nav-tabs.form-nav .nav-item {
    flex: 1;
    justify-content: center;
  }

  @media (min-width: 900px) {
    .nav-tabs.form-nav .nav-item {
      flex: inherit;
    }
  }

  .nav-tabs.form-nav .nav-item + .nav-item {
    border-left: 2px dotted #9d9fb4;
  }

  .nav-tabs.form-nav .nav-link {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: $base-text-color;
    margin-right: 3px;
    padding: 5px 35px 5px 15px;
    text-decoration: none;
  }

  .nav-tabs.form-nav .nav-link:hover, .nav-tabs.form-nav .nav-link:focus, .nav-tabs.form-nav .nav-link:active {
    color: $sapphire-blue-10;
  }

  .nav-tabs.form-nav .nav-link::before, .nav-tabs.form-nav .nav-link::after {
    border-radius: 50%;
    display: inline-block;
    line-height: 21px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }

  .nav-tabs.form-nav .nav-link::after {
    background-color: #fff;
    font-family: 'iconfont', serif;
    font-size: 12px;
    z-index: 10;
  }

  .nav-tabs.form-nav .nav-link::before {
    background-color: #fff;
    border-radius: 50%;
    content: '';
    height: 20px;
  }

  .nav-tabs.form-nav .nav-link.active {
    color: $sapphire-blue-10;
  }

  .nav-tabs.form-nav.form-steps {
    overflow: hidden;
    padding: 0;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps {
      display: flex;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-item {
    background-color: $black-coral;
    margin-bottom: 0;
    padding: 10px 0 10px 25px;
    position: relative;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-item {
      flex-direction: row;
      padding-left: 14px;
    }
  }

  @media (max-width: 899px) {
    .nav-tabs.form-nav.form-steps .nav-item:last-child {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
    .nav-tabs.form-nav.form-steps .nav-item:last-child::after {
      border: 0;
    }
  }

  @media (min-width: 600px) {
    .nav-tabs.form-nav.form-steps .nav-item {
      flex-basis: auto;
      flex-grow: 0;
      width: auto;
    }
    .nav-tabs.form-nav.form-steps .nav-item:last-child {
      flex-grow: 1;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-item + .nav-item {
    border-left: 0;
  }

  .nav-tabs.form-nav.form-steps .nav-item::after, .nav-tabs.form-nav.form-steps .nav-item::before {
    border-bottom: 26px solid transparent;
    border-left: 24px solid $black-coral;
    border-top: 26px solid transparent;
    content: '';
    height: 0;
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    z-index: 2;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-item::after, .nav-tabs.form-nav.form-steps .nav-item::before {
      border-width: 23px 17px;
      right: -16px;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-item::before {
    border-left: 20px solid $black-coral;
    left: 0;
    right: auto;
    z-index: 0;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-item::before {
      border-left-width: 17px;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-item:first-child {
    border-radius: 5px 0 0 5px;
    padding-left: 0;
  }

  .nav-tabs.form-nav.form-steps .nav-item:first-child::before {
    border: 0;
  }

  .nav-tabs.form-nav.form-steps .nav-item.complete, .nav-tabs.form-nav.form-steps .nav-item.active, .nav-tabs.form-nav.form-steps .nav-item.visited {
    background-color: $sapphire-blue-10;
  }

  .nav-item.complete-done {
    background-color: $sea-green-10 !important;
    margin-left: 0 !important;

    &:hover {
      cursor: not-allowed;
      pointer-events: none;
    }

    a {
      cursor: not-allowed;
      pointer-events: none;
      color: white !important;

      &:before {
        color: $black-coral-10 !important;
      }
    }
  }

  .nav-item.complete-done::before {
    z-index: -1 !important;
  }

  .nav-item.complete-done::after {
    z-index: -1 !important;

  }

  .nav-tabs.form-nav.form-steps .nav-item.complete .nav-link, .nav-tabs.form-nav.form-steps .nav-item.active .nav-link, .nav-tabs.form-nav.form-steps .nav-item.visited .nav-link {
    color: #fff;
    outline-color: #ffcf00;
  }

  .nav-tabs.form-nav.form-steps .nav-item.complete .nav-link:focus, .nav-tabs.form-nav.form-steps .nav-item.active .nav-link:focus, .nav-tabs.form-nav.form-steps .nav-item.visited .nav-link:focus {
    outline-color: #ffcf00;
  }

  .nav-tabs.form-nav.form-steps .nav-item.complete::after, .nav-tabs.form-nav.form-steps .nav-item.active::after, .nav-tabs.form-nav.form-steps .nav-item.visited::after {
    border-left-color: $sapphire-blue-10;
  }

  .nav-tabs.form-nav.form-steps .nav-item.done .nav-link {
    cursor: not-allowed;
  }

  .nav-tabs.form-nav.form-steps .nav-item.done .nav-link:focus, .nav-tabs.form-nav.form-steps .nav-item.done .nav-link:active {
    pointer-events: none;
  }

  .nav-tabs.form-nav.form-steps .nav-item.disabled .nav-link {
    cursor: not-allowed;
    pointer-events: none;
  }

  .nav-tabs.form-nav.form-steps .nav-item.disabled .nav-link:focus, .nav-tabs.form-nav.form-steps .nav-item.disabled .nav-link:active {
    pointer-events: none;
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(1) .nav-link::before {
    z-index: 20;
    content: "\31";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(2) .nav-link::before {
    z-index: 20;
    content: "\32";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(3) .nav-link::before {
    z-index: 20;
    content: "\33";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(4) .nav-link::before {
    z-index: 20;
    content: "\34";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(5) .nav-link::before {
    z-index: 20;
    content: "\35";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(6) .nav-link::before {
    z-index: 20;
    content: "\36";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(7) .nav-link::before {
    z-index: 20;
    content: "\37";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(8) .nav-link::before {
    z-index: 20;
    content: "\38";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(9) .nav-link::before {
    z-index: 20;
    content: "\39";
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(1) .nav-link.no-number:before {
    all: initial;
  }

  .nav-tabs.form-nav.form-steps .nav-item:nth-child(1) .nav-link.no-number:after {
    all: initial;
  }

  .nav-tabs.form-nav.form-steps .nav-link {
    font-size: 1rem;
    line-height: initial;
    padding: 5px 5px 5px 35px;
    text-align: left;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-link {
      font-size: 12px;
      padding-left: 25px;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-link::before, .nav-tabs.form-nav.form-steps .nav-link::after {
    left: 10px;
    right: initial;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-link::before, .nav-tabs.form-nav.form-steps .nav-link::after {
      font-size: 8px;
      height: 14px;
      left: 6px;
      line-height: 14px;
      width: 14px;
    }
  }

  .nav-tabs.form-nav.form-steps .nav-link::before {
    color: $base-text-color;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
  }

  @media (max-width: 599px) {
    .nav-tabs.form-nav.form-steps .nav-link::before {
      height: 14px;
    }
  }

  @media (min-width: 600px) {
    .form-mobile-nav {
      display: none;
    }
  }

}
.number-wrapper .number-nav {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 599px) {
  .number-wrapper .number-nav {
    display: none;
  }
}

.form-mobile-nav .dropdown-toggle {
  background: #fff;
  border: 2px solid #dbdfe2;
  border-radius: 5px;
  color: #34394c;
  cursor: pointer;
  font-size: 20px;
  font-weight: normal;
  height: inherit;
  line-height: 17px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0.575rem 0.75rem;
  padding-right: 60px;
  text-overflow: ellipsis;
}

@media (max-width: 599px) {
  .form-mobile-nav .dropdown-toggle {
    line-height: 1.25;
    padding: 7px 5px;
    padding-right: 35px;
    text-align: left;
    width: 100%;
  }
}

.form-mobile-nav .dropdown-toggle::after {
  border: 0;
  color: $base-text-color;
  content: '\e913';
  filter: blur(0);
  font-family: 'iconfont', serif;
  font-size: 1rem;
  height: auto;
  line-height: inherit;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: transform 0.2s ease-in-out;
  width: auto;
}

.form-mobile-nav .dropdown-toggle:focus {
  border-color: #9d9fb4;
  box-shadow: none;
}

.form-mobile-nav .dropdown-toggle[aria-expanded='true'] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #9d9fb4;
}

.form-mobile-nav .dropdown-toggle[aria-expanded='true']::after {
  color: $sapphire-blue-10;
  filter: blur(0);
  height: auto;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotateZ(90deg) rotateX(180deg);
  transition: transform 0.2s ease-in-out;
  width: auto;
}

.form-mobile-nav .dropdown-toggle .filter-option {
  position: relative;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.form-mobile-nav .dropdown-toggle .filter-option.placeholder {
  color: #9d9fb4;
}

.form-mobile-nav .dropdown-toggle.disabled {
  cursor: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.form-mobile-nav .dropdown-toggle.disabled:focus {
  border-color: #dbdfe2;
}

.form-mobile-nav .nav {
  display: block;
}

.form-mobile-nav .dropdown-menu {
  border: 2px solid #9d9fb4;
  border-radius: 0;
  margin: -4px 0;
  width: 100%;
}

.form-mobile-nav li {
  flex-direction: row;
  justify-content: flex-start;
  line-height: 1.8;
  padding: 0 0.375rem;
  text-align: left;
  width: 100%;
}

.form-mobile-nav li a {
  color: #34394c;
  display: block;
  height: 100%;
  padding: 0 0.375rem;
}

@media (max-width: 599px) {
  .form-mobile-nav li a {
    font-size: 18px;
    width: 100%;
  }
}

.form-mobile-nav li a:focus {
  background-color: #aae1f8;
  border-radius: 3px;
}

.form-mobile-nav li a.active {
  color: $sapphire-blue-10;
}

.form-mobile-nav li a.active:focus {
  color: #34394c;
}

.form-mobile-nav li a::after {
  display: inline-block;
  font-family: 'iconfont', serif;
  font-size: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}

.form-mobile-nav li a::before {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.form-mobile-nav li a.complete::after {
  color: #308653;
  content: "\ec63";
}

.form-mobile-nav li a.error::after {
  color: #ff7373;
  content: "\ec85";
}

.form-mobile-nav li a.warning::after {
  color: #ffcf00;
  content: "\ec6a";
}

.form-mobile-nav li a.locked {
  color: #9d9fb4;
  cursor: not-allowed;
}

.form-mobile-nav li a.locked:focus, .form-mobile-nav li a.locked:active {
  pointer-events: none;
}

.form-mobile-nav li a.locked::after {
  color: #9d9fb4;
  content: "\ec7c";
}

.form-mobile-nav.no-flex-dropdown .nav {
  display: block;
}

.button-link.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-vertical {
  border: 0;
}

@media (max-width: 599px) {
  .nav-vertical {
    display: none;
  }
}

@media (min-width: 600px) {
  .nav-vertical {
    display: block;
  }
}

@media (min-width: 600px) {
  .nav-vertical-wrapper {
    display: flex;
  }
}

@media (min-width: 600px) {
  .nav-vertical-wrapper .form-header {
    flex: 0 1 auto;
    margin-bottom: 0;
  }
}

.nav-vertical-wrapper .tab-content {
  background-color: $black-coral;
  border: 2px solid #dbdfe2;
  border-radius: 0.25rem;
  flex: 1 1 auto;
  padding: 20px;
}

.nav-vertical .nav-item {
  display: block;
  text-align: right;
  white-space: normal;
  width: auto;
}

.nav-vertical .nav-link {
  border: 0;
  color: $base-text-color;
  padding-right: 2rem;
}

.nav-vertical .nav-link:hover, .nav-vertical .nav-link:focus, .nav-vertical .nav-link:active {
  color: $sapphire-blue-10;
}

.nav-vertical .nav-link.active {
  background: transparent;
  color: $sapphire-blue-10;
}

.nav-vertical .nav-link.active::before, .nav-vertical .nav-link.active::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  position: absolute;
  right: -2px;
  top: 50%;
}

.nav-vertical .nav-link.active::before {
  border-right-color: #dbdfe2;
  border-width: 13px;
  margin-top: -13px;
}

.nav-vertical .nav-link.active::after {
  border-right-color: $black-coral;
  border-width: 10px;
  margin-top: -10px;
}

.nav-vertical .nav-divider {
  border-top: 1px dashed $base-text-color;
  margin: 0.5rem 2rem 0.5rem 0;
}

.nav-tabs.nav-arrows {
  border-bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-tabs.nav-arrows .nav-item {
  flex: 0 0 33%;
  justify-content: flex-end;
  margin: 0;
  white-space: normal;
  width: auto;
}

@media (min-width: 900px) {
  body:not(.help-center-open):not(.temp) .nav-tabs.nav-arrows .nav-item {
    flex: 0 0 33%;
  }
}

@media (min-width: 1200px) {
  .nav-tabs.nav-arrows .nav-item {
    flex: 0 0 33%;
  }
}

.nav-tabs.nav-arrows .nav-link {
  background-color: transparent;
  border: transparent;
  color: $base-text-color;
  padding: 0.8rem 0;
  text-align: center;
}

.nav-tabs.nav-arrows .nav-link.active, .nav-tabs.nav-arrows .nav-link:hover, .nav-tabs.nav-arrows .nav-link:focus {
  color: $sapphire-blue-10;
}

.nav-tabs.nav-arrows .nav-link.active::after {
  border: solid transparent;
  border-bottom-color: #fff;
  border-width: 10px;
  bottom: 0;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -10px;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.nav-tabs.section-tabs {
  border: 0;
}

@media (max-width: 599px) {
  .nav-tabs.section-tabs {
    display: none;
  }
}

.nav-tabs.section-tabs .nav-item + .nav-item {
  margin-left: 0.5rem;
}

.nav-tabs.section-tabs .nav-link {
  background-color: transparent;
  border: 0;
  color: #34394c;
  display: inline-block;
  flex: 0 0 auto;
  padding: 0.7rem 1.5rem;
  position: relative;
  width: auto;
}

.nav-tabs.section-tabs .nav-link.active {
  color: $sapphire-blue-10;
}

.nav-tabs.section-tabs .nav-link:not(.active):hover {
  color: $sapphire-blue-10;
}

@media (max-width: 599px) {
  .nav-tabs-form-header {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 600px) {
  .nav-tabs-form-header {
    margin: 0;
  }
}

.tab-content.tab-sections {
  background-color: #fff;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  padding: 20px;
}

@media (max-width: 599px) {
  .tab-content.tab-sections {
    border-radius: 0;
    padding: 20px 10px;
  }
}

.tab-content.tab-sections.gray-sections {
  background-color: $black-coral;
}

.gray-tabs .nav-link.active {
  background-color: $black-coral;
}

.gray-tabs .nav-link:not(.active):hover {
  background-color: #fff;
}

.gray-tabs .nav-link:not(.active):focus {
  background-color: #fff;
  outline: none;
}

.white-tabs .nav-link.active {
  background-color: #fff;
}

.white-tabs .nav-link:not(.active):hover {
  background-color: $black-coral;
}

.white-tabs .nav-link:not(.active):focus {
  background-color: $black-coral;
  outline: none;
}

.nav-search::before {
  content: "\ec6f";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-search::before {
    font-size: 1.4rem;
  }
}

.nav-accessibility::before {
  content: "\ec6c";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-accessibility::before {
    font-size: 1.4rem;
  }
}

.nav-messages::before {
  content: "\ec75";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-messages::before {
    font-size: 1.4rem;
  }
}

.nav-notifications::before {
  content: "\ec74";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-notifications::before {
    font-size: 1.4rem;
  }
}

.nav-favourites::before {
  content: "\ec82";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-favourites::before {
    font-size: 1.4rem;
  }
}

.nav-help-center::before {
  content: "\e90b";
  font-family: 'iconfont', serif;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 599px) {
  .nav-help-center::before {
    font-size: 1.4rem;
  }
}

.nav-item {
  display: flex;
  flex: 0 0 60px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

@media (min-width: 900px) {
  body:not(.help-center-open):not(.temp) .nav-item {
    flex: 0 0 auto;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .nav-item {
    flex: 0 0 auto;
    width: auto;
  }
}

.nav-item .nav-link {
  position: relative;
}

.nav-item .nav-link:hover .unread, .nav-item .nav-link:focus .unread, .nav-item .nav-link:active .unread {
  background-color: #b84444;
}

.nav-item .nav-link.active::before {
  color: $sapphire-blue-10;
}

.nav-item .nav-link .unread {
  background-color: #ff7373;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  height: 15px;
  left: calc(50% - 2px);
  line-height: 15px;
  min-width: 15px;
  padding: 0 4px;
  position: absolute;
  top: 0;
}

@media (max-width: 599px) {
  .nav-item .nav-link .unread {
    left: 50%;
    top: 5px;
  }
}

@media (max-width: 1199px) {
  body.help-center-open:not(.temp) .nav-item-desktop-only {
    display: none;
  }
}

@media (max-width: 899px) {
  .nav-item-desktop-only {
    display: none;
  }
}

@media (min-width: 900px) {
  .nav-item-shrink-desktop {
    flex: 1 0 auto;
    min-width: 0;
  }
}

.nav-favourites.selected::before {
  background-size: 100%;
}

.navbar-right .nav-item {
  border-left: 1px dotted #9d9fb4;
}

@media (min-width: 900px) {
  body:not(.help-center-open):not(.temp) .navbar-right .nav-item {
    border: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-right .nav-item {
    border: 0;
  }
}

@media (min-width: 900px) {
  body:not(.help-center-open):not(.temp) .nav-separator::before {
    border-style: solid;
    border-width: 0 0 0 3px;
    content: '';
    display: block;
    height: 3rem;
  }
}

@media (min-width: 1200px) {
  .nav-separator::before {
    border-style: solid;
    border-width: 0 0 0 3px;
    content: '';
    display: block;
    height: 3rem;
  }
}

.navbar-light .navbar-nav .nav-language:hover, .navbar-light .navbar-nav .nav-language:focus, .navbar-light .navbar-nav .nav-language:active {
  color: #0083ba;
  text-decoration: underline;
}

.nav-logout {
  flex: 0 0 auto;
  padding: 0 20px;
  width: auto;
}

@media (max-width: 599px) {
  .nav-logout {
    display: none;
  }
}

@media (min-width: 900px) {
  .nav-logout {
    padding-right: 40px;
  }
}

.nav-search {
  cursor: pointer;
  text-align: left;
}

@media (max-width: 599px) {
  .nav-search {
    font-size: 0;
  }
}

@media (min-width: 600px) {
  .nav-search::before {
    margin-right: 15px;
    position: absolute;
    transition: margin-left 0.3s;
    z-index: 1;
  }
  .nav-search.active::before {
    margin-left: 7px;
    pointer-events: none;
  }
}

.nav-search:not(.active):hover {
  color: #0083ba;
}

.nav-search::before {
  font-size: 1.1rem;
}

.nav-search-anchor {
  height: 25px;
  position: absolute;
  width: 25px;
}

@media (max-width: 599px) {
  .nav-search-desktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .nav-search-mobile {
    display: none;
  }
}

.nav-search div {
  display: inline;
  position: relative;
}

.nav-search div .clear {
  height: 1.4rem;
  line-height: 20px;
  margin: 0;
  right: 0;
  top: 0;
}

.nav-search .navbar-search {
  border-width: 0;
  display: inline-block;
  font-size: 12px;
  height: 24px;
  line-height: 1;
  max-width: 300px;
  opacity: 0;
  padding: 0;
  transition: width 0.3s, opacity 0.3s, padding-left 0.3s;
  vertical-align: middle;
  width: 0;
}

@media (max-width: 599px) {
  .nav-search .navbar-search {
    display: none;
  }
}

.nav-search .navbar-search.open {
  border-width: 2px;
  opacity: 1;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 0.75rem;
  padding-top: 0;
  width: calc(100% - 20px);
}

.nav-search .navbar-search.has-clear {
  padding-right: 25px;
}

.navbar-nav .nav-item .nav-search {
  margin: 0 20px;
  padding: 0;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-nav .nav-item .nav-search {
    margin-left: 40px;
  }
}

@media (min-width: 1500px) {
  .navbar-nav .nav-item .nav-search {
    margin-left: 40px;
  }
}

.search-placeholder {
  margin-left: 25px;
  opacity: 1;
  transition: width 0.2s, opacity 0.2s;
  vertical-align: middle;
  width: initial;
}

@media (max-width: 599px) {
  .search-placeholder {
    display: none;
  }
}

.search-placeholder.hidden {
  opacity: 0;
  width: 0;
}

.nav-user {
  padding-bottom: 8px;
  padding-right: 0;
  padding-top: 8px;
}

.nav-user-name {
  color: $base-text-color;
  line-height: 1;
  margin-left: 0.3rem;
  max-width: calc(100% - 32px);
  padding-right: 0.5rem;
  text-align: left;
  vertical-align: middle;
}

@media (min-width: 900px) {
  .nav-user-name {
    display: inline-block;
  }
}

.nav-user-name span {
  display: block;
}

.navbar-toggler {
  background-color: $base-text-color;
  border-width: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  transition: none;
  width: 60px;
  z-index: 203;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler {
    border-width: 0 0 1px;
    position: static;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler {
    border-width: 0 0 1px;
    position: static;
  }
}

@media (min-width: 600px) {
  .navbar-toggler {
    transition: left 0.5s;
  }
}

.navbar-toggler.menu-open {
  left: calc(100% - 60px);
}

@media (min-width: 600px) {
  .navbar-toggler.menu-open {
    left: 300px;
  }
}

@media (min-width: 900px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.menu-open {
    left: 300px;
  }
}

@media (min-width: 1200px) {
  .navbar-toggler.menu-open {
    left: 300px;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.menu-open {
    left: 0;
    transition: none;
  }
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.disabled {
    pointer-events: none;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.disabled {
    pointer-events: none;
  }
}

.navbar-toggler span {
  background-color: #fff;
  border-radius: 20%;
  display: block;
  height: 3px;
  margin: 6px 0;
  transition: margin 0.25s, opacity 0.25s, transform 0.5s;
  width: 100%;
}

.navbar-toggler.menu-open {
  height: 60px;
  position: fixed;
  top: 0;
  width: 60px;
}

@media (min-width: 1200px) {
  .navbar-toggler.menu-open {
    position: absolute;
  }
}

.navbar-toggler.menu-open span {
  margin: 5px 0;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.menu-open span {
    margin: 6px 0;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.menu-open span {
    margin: 6px 0;
  }
}

.navbar-toggler.menu-open span:first-of-type {
  transform: rotate(135deg);
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.menu-open span:first-of-type {
    transform: none;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.menu-open span:first-of-type {
    transform: none;
  }
}

.navbar-toggler.menu-open span:nth-of-type(2) {
  opacity: 0;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.menu-open span:nth-of-type(2) {
    opacity: 1;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.menu-open span:nth-of-type(2) {
    opacity: 1;
  }
}

.navbar-toggler.menu-open span:last-of-type {
  transform: rotate(-135deg);
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-toggler.menu-open span:last-of-type {
    transform: none;
  }
}

@media (min-width: 1500px) {
  .navbar-toggler.menu-open span:last-of-type {
    transform: none;
  }
}

.navbar-expand .navbar-toggler {
  display: inline-block;
}

.navbar {
  background-color: $black-coral;
  font-size: 0.85714rem;
  min-height: 60px;
  z-index: 203;
}

@media (max-width: 599px) {
  .navbar {
    font-size: 0.6rem;
  }
}

.navbar-header {
  height: 60px;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-header {
    background-color: #fff;
    min-width: 300px;
    width: 300px;
  }
}

@media (min-width: 1500px) {
  .navbar-header {
    background-color: #fff;
    min-width: 300px;
    width: 300px;
  }
}

.navbar-nav {
  height: 60px;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-nav {
    align-items: center;
    padding: 1rem 0;
  }
}

@media (min-width: 1500px) {
  .navbar-nav {
    align-items: center;
    padding: 1rem 0;
  }
}

.navbar-nav:first-of-type {
  flex: 0 1 auto;
  margin-left: 60px;
  width: 36%;
}

@media (min-width: 600px) {
  .navbar-nav:first-of-type .nav-item {
    flex: 1 1 auto;
  }
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-nav:first-of-type {
    flex: 0 10 auto;
    margin-left: 0;
  }
}

@media (min-width: 1500px) {
  .navbar-nav:first-of-type {
    flex: 0 10 auto;
    margin-left: 0;
  }
}

.navbar-right {
  margin-left: auto;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) .navbar-right {
    flex: 0 1 auto;
    justify-content: flex-end;
    min-width: 0;
  }
}

@media (min-width: 1500px) {
  .navbar-right {
    flex: 0 1 auto;
    justify-content: flex-end;
    min-width: 0;
  }
}

.navbar-nav.navbar-unauthorized {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.navbar-nav.navbar-unauthorized .nav-item {
  border: 0;
  flex: 0 1 auto;
  width: auto;
}

#desktop-menu-3 .navbar-left {
  display: flex;
  float: left;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) #desktop-menu-3 .navbar-left {
    max-width: 700px;
    width: 70%;
  }
}

@media (min-width: 1500px) {
  #desktop-menu-3 .navbar-left {
    max-width: 700px;
    width: 70%;
  }
}

#desktop-menu-3 .navbar-right {
  display: none;
  float: right;
  height: 100%;
  text-align: right;
}

@media (min-width: 1200px) {
  body:not(.help-center-open):not(.temp) #desktop-menu-3 .navbar-right {
    display: inline-block;
  }
}

@media (min-width: 1500px) {
  #desktop-menu-3 .navbar-right {
    display: inline-block;
  }
}

#desktop-menu-3 .navbar-right li:not(:last-of-type) {
  margin-right: 10px;
}

.temp .desktop-nav {
  align-items: stretch;
  align-self: baseline;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: auto;
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px 20px;
  position: relative;
  width: 100%;
}

@media (max-width: 899px) {
  .temp .desktop-nav {
    display: none;
  }
}

.temp .desktop-nav::after {
  border-bottom: 2px solid #fff;
  bottom: 0;
  content: '';
  left: 20px;
  position: absolute;
  right: 20px;
}

.temp .desktop-nav .nav-item {
  flex: 1 1 auto;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: auto;
}

.temp .desktop-nav .nav-item:first-of-type {
  padding-left: 0;
}

.temp .desktop-nav .nav-item:last-of-type {
  padding-right: 0;
}

.temp .desktop-nav .nav-item.with-title {
  justify-content: flex-start;
}

.temp .desktop-nav .nav-item-title {
  color: #9d9fb4;
  font-size: 0.85714rem;
  margin-bottom: 0;
}

@media (max-width: 599px) {
  .temp .desktop-nav .nav-item-title {
    font-size: 0.6rem;
  }
}

.temp .desktop-nav .nav-item:not(:last-of-type) {
  border-right: 1px dotted $base-text-color;
}

.temp .desktop-nav .nav-link {
  padding: 0;
}

.temp .desktop-nav .dropdown.user-dropdown .dropdown-toggle::after {
  border: 0;
  content: "\e913";
  font-family: 'iconfont', serif;
  font-size: 0.7rem;
  height: auto;
  position: absolute;
  right: 0;
  text-decoration: underline;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: transform 0.2s;
  width: auto;
}

.temp .desktop-nav .dropdown.user-dropdown .dropdown-toggle::after {
  text-decoration: none;
}

.temp .desktop-nav .dropdown.user-dropdown.show .dropdown-toggle::after {
  transform: translateY(-50%) rotateZ(90deg) rotateX(180deg);
}

.temp .desktop-nav .dropdown.user-dropdown .dropdown-toggle:focus {
  outline: auto;
}

.temp .desktop-nav .dropdown.user-dropdown .dropdown-menu {
  border-radius: 5px;
  box-shadow: 3px 3px 7px $base-text-color;
  min-width: 220px;
  padding: 0;
}

.temp .desktop-nav .nav-language {
  text-align: right;
}

.temp .desktop-nav .nav-language .nav-link {
  white-space: nowrap;
}

.temp .desktop-nav .nav-logout .btn-logout {
  align-self: flex-end;
  text-decoration: none;
  white-space: nowrap;
}

.temp .mobile-nav {
  background-color: $base-text-color;
  border-right: 1px solid #fff;
  color: #fff;
  font-size: 1rem;
  height: 100%;
  left: 0;
  max-width: calc(100% - 60px);
  position: absolute;
  top: 0;
  transition: left 0.5s;
  width: 260px;
  z-index: 204;
}

@media (max-width: 599px) {
  .temp .mobile-nav {
    font-size: 0.7rem;
  }
}

@media (min-width: 900px) {
  .temp .mobile-nav {
    display: none;
  }
}

.temp .mobile-nav.closed {
  left: -260px;
}

.temp .mobile-nav .navbar-toggler {
  height: 60px;
  left: auto;
  position: absolute;
  right: -61px;
}

.temp .mobile-nav .nav-item {
  border-bottom: 1px solid #fff;
  justify-content: center;
  min-height: 60px;
  text-align: left;
  white-space: normal;
  width: auto;
}

.temp .mobile-nav .nav-item span {
  display: inline-block;
  line-height: 1.5;
  vertical-align: middle;
}

.temp .mobile-nav .nav-link {
  color: #fff;
  height: 100%;
  line-height: 1.4;
  padding: 10px 20px;
}

.temp .mobile-nav .nav-link:hover {
  color: #fff;
}

.temp .mobile-nav .nav-link-collapse::after {
  content: "\e913";
  font-family: 'iconfont', serif;
  font-size: 0.7rem;
  line-height: 1rem;
  position: absolute;
  right: 10px;
  transform: rotate(-90deg);
  transition: transform 0.2s;
}

@media (min-width: 600px) {
  .temp .mobile-nav .nav-link-collapse::after {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}

.temp .mobile-nav .nav-link-collapse.collapsed::after {
  transform: rotate(0deg);
}

.temp .mobile-nav .nav-language {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.temp .mobile-nav .nav-language .nav-link {
  flex: 1 1 auto;
}

@media (min-width: 900px) {
  .temp .nav-representative {
    background-color: #fff;
    border-radius: 5px;
    color: $base-text-color;
    padding: 5px;
  }
  .temp .nav-representative li {
    border-radius: 5px;
  }
  .temp .nav-representative li + li {
    margin-top: 5px;
  }
}

.temp .nav-representative.collapse {
  display: none;
}

.temp .nav-representative.collapse.show, .temp .nav-representative.collapsing {
  display: block;
  min-height: auto;
}

.temp .nav-representative-container {
  padding: 20px;
}

.temp .nav-representative-id {
  color: #9d9fb4;
  display: block;
  font-size: 0.85714rem;
}

@media (max-width: 599px) {
  .temp .nav-representative-id {
    font-size: 0.6rem;
  }
}

.temp .nav-representative-link {
  color: inherit;
  display: block;
  height: 100%;
  padding: 10px;
  width: 100%;
}

.temp .nav-representative-link:active, .temp .nav-representative-link:hover, .temp .nav-representative-link:focus {
  background-color: $black-coral;
}

.temp .nav-representative .active .nav-representative-link {
  background-color: #aae1f8;
}

@media (min-width: 600px) {
  .temp .nav-user {
    pointer-events: none;
  }
}

.temp .nav-user-name {
  display: inline-block;
}

@media (max-width: 599px) {
  .temp .nav-user-name {
    display: none;
  }
}

.temp .nav-user-last-name {
  color: #9d9fb4;
}

.temp .navbar {
  font-size: 1rem;
}

@media (max-width: 599px) {
  .temp .navbar {
    font-size: 0.7rem;
  }
}

@media (min-width: 900px) {
  .temp .navbar {
    background-color: transparent;
    flex-shrink: 0;
  }
}

.temp .navbar-right {
  align-self: flex-end;
  flex-direction: row;
}

@media (min-width: 900px) {
  .temp .navbar-right {
    display: none;
  }
}

.temp .navbar-right .nav-item {
  border: 0;
  flex: 1 0 auto;
  height: 100%;
  width: auto;
}

@media (max-width: 599px) {
  .temp .navbar-right .nav-link {
    padding-left: 14px;
    padding-right: 14px;
  }
}
