@import "../../../../../variables";

.form-group.ads-form-group {
  margin: -8px;
  @media screen and (min-width: $tablet-first) {
    flex-wrap: nowrap;
    flex-grow: 1 !important;
    .apartment-select-form-group {
      padding-left: 8px;
    }
  }

  .input-wrapper, .apartment-select-form-group {
    padding: 8px;
  }

  .input-wrapper {
    .rbt {
      margin-left: 0;
    }

    .rbt-input-hint-container {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .rbt-menu{
      max-height: none !important;
    }
  }

  .apartment-select {
    .form-control {
      &.is-valid, &.is-invalid {
        padding-right: calc(1.5em + 0.75rem);
      }
    }
  }
}
