@import "src/App/variables";

.activity-license-documents-wrapper {
  p {
    margin-bottom: 32px;
  }

  h4 {
    padding-top: 0;
    margin-bottom: 16px;
  }

  .file-container {
    margin-left: 86px;
    width: 800px;
    max-width: 100%;

    @media screen and (max-width: $tablet-last) {
      margin-left: 0;
    }
  }
}