@import "src/App/variables";

.quick-links {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 30px);
  grid-column-gap: 10px;

  a {
    text-decoration: underline;
    color: $sapphire-blue-10;
  }
}