.gp-lists-base-modal {
  &.modal-dialog {
    max-width: 560px;
    .loader {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .modal-header {
      padding: 24px;
    }

    .modal-body {
      padding-right: 24px;
      padding-bottom: 24px;
      padding-left: 24px;
    }

    .modal-footer {
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 24px;
      margin: 0 -12px;
      & > * {
        margin: 0 12px;
      }
    }

  }
}