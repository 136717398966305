.representative-modal-info-container {
  font-size: 18px;
}

.representative-modal-label-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  min-width: 160px;
  padding-top: 6px;

  > :first-child {
    font-size: 16px;
  }

  .required::after {
    color: red;
    content: " *";
  }
}

.representative-modal-item {
  height: 60px;
  margin-bottom: 10px;

  &.select {
    height: auto;
    margin-bottom: 30px;
  }
}

.representative-modal-input-container {
  width: 100%;

  > input {
    font-size: 18px;
  }
}

.representative-modal-select-container {
  align-items: center;
  display: flex;
}

.representative-modal-date-wrapper {
  width: 100%;

  > input {
    font-size: 18px;
  }
}

.representative-modal-validation-error-container {
  padding-top: 4px;

  > div {
    min-height: auto;

    > div {
      padding-top: 3px;
    }
  }
}

.representative-modal-info-icon {
  margin-left: 10px;
}

.representative-type-select {
  max-width: 375px;
  width: 100%;

  > .form-control {
    > div {
      > div {
        display: flex;
        @media (max-width: 410px) {
          font-size: 14px;
        }
      }
    }
  }
}