#signing-modal {

  .mobile-signing-wrapper {
    .input-group {
      > .input-group-prepend {
        flex: 0 0 15%;
      }

      .input-group-text {
        width: 100%;
      }
    }
  }
}