@import "src/App/variables";

#add-new-employee-modal {
  max-width: 100%;
  width: 560px;
  font-family: Roboto, sans-serif;

  .fw-700 {
    font-weight: 700;
  }

  .modal-content {
    padding: 24px;

    > * {
      padding: 0;
    }

    .modal-header {
      h4 {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .modal-body {
      margin: 16px 0 48px;

      p {
        margin-bottom: 8px;
        font-weight: 400;
        size: 16px;
        line-height: 24px;
      }

      .occupation-name-wrapper {
        .occupation-name, .speciality-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        margin-bottom: 16px;
      }

      h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
      }

      .card {
        background: $black-coral-0;
        border: 1px solid $black-coral-3;
        box-sizing: border-box;
        padding: 16px 24px;

        &.employee-selection {
          display: grid;
          column-count: 2;
          column-gap: 10px;
          grid-template-columns: 80px auto;
          align-items: center;
          margin-bottom: 24px;
        }


        .employee-row {
          display: grid;
          column-count: 2;
          column-gap: 10px;
          grid-template-columns: 15px auto;
          //align-items: center;
        }
      }

      label {
        display: block;
        text-align: right;
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .mw-fit-content {
    max-width: fit-content;
  }

  .opacity05 {
    opacity: 0.5;
  }

  .speciality-code-hint {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 6px;
  }
  
  .async-custom-select {
    input {
      opacity: 1 !important;
    }
  }
}