@import "src/App/variables";

.expanded {
  background-color: $black-coral-1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.accordion-header {
  display: flex;
  align-items: center;
  min-height: 48px;
  margin: 0;
  border-bottom: 1px solid $black-coral-1;
  padding-left: 48px;

  .title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: $base-text-color;
  }
}

.location {
  padding-left: 64px;
}

.service {
  padding-left: 80px;
}

@media screen and (max-width: $mobile-last) {
  .accordion-header {
    padding-left: 16px;

  }
  .location {
    padding-left: 16px;
  }

  .service {
    padding-left: 16px;
  }
}
