@import "src/App/variables";

.activity-license-business-area-wrapper {
  .business-area-form {
    margin-top: 32px;
    display: grid;
    column-count: 2;
    width: 100%;
    max-width: 600px;
    column-gap: 10px;
    grid-template-columns: 190px auto;
    align-items: center;

    @media screen and (max-width: $mobile-last) {
      grid-template-columns: auto;
      column-count: 1;
    }

    label {
      display: block;
      text-align: right;
      margin: 0;
    }
  }
}
