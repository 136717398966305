@import "src/App/variables";

.btn {
  box-shadow: none !important;
  border-radius: 40px !important;
  max-height: none;
  border-width: 2px !important;

  &-unstyled {
    outline: none;
    border: 0;
    border: none;
    background: transparent;
  }

  &-primary {
    padding: 10px 45px !important;
    background-color: $sapphire-blue-10 !important;
    border-color: $sapphire-blue-10 !important;

    font-size: 16px !important;
    line-height: 16px !important;

    &:not(.disabled), &:not([disabled]) {
      &:hover {
        background-color: $sapphire-blue-12 !important;
        border-color: $sapphire-blue-10 !important;
      }

      &:focus {
        box-shadow: inset 0 0 0 2px $white !important;
      }
    }
  }

  &.disabled {
    background-color: $black-coral-2 !important;
    border-color: $black-coral-2 !important;
    color: $black-coral-6 !important;
    cursor: default;

    &:hover {
      background-color: $black-coral-2 !important;
      border-color: $black-coral-2 !important;
      color: $black-coral-0 !important;
    }
  }

  &-secondary {
    padding: 10px 43px !important;

    background: $black-coral-0 !important;
    border-color: $black-coral-10 !important;
    color: $black-coral-15 !important;

    box-sizing: border-box;
    white-space: nowrap;

    font-size: 16px !important;
    line-height: 16px !important;

    &.disabled {
      background-color: $black-coral !important;
      border-color: $black-coral !important;
      color: $black-coral-6 !important;
      cursor: default;

      &:hover {
        background-color: $black-coral !important;
        border-color: $black-coral-2 !important;
        color: $black-coral-6 !important;
      }
    }

    &:not(.disabled), &:not([disabled]) {
      &:hover {
        border-color: $black-coral-1 !important;
      }

      &:focus {
        border-color: $sapphire-blue-10 !important;
      }
    }
  }

  &-danger {
    padding: 10px 45px !important;

    background: $jasper-10 !important;
    border-color: $jasper-10 !important;
    color: $black-coral-0 !important;

    box-sizing: border-box;

    font-size: 16px !important;
    line-height: 16px !important;

    &.disabled {
      background-color: $black-coral-2 !important;
      border-color: $black-coral-2 !important;
      cursor: default;

      &:hover {
        background-color: $black-coral-2 !important;
        border-color: $black-coral-2 !important;
        color: $black-coral-0 !important;
      }
    }

    &:hover {
      background-color: $jasper-12 !important;
      border-color: $jasper-12 !important;
    }

    &:focus {
      border-color: $jasper-10 !important;
    }
  }

  &-success {
    padding: 10px 45px !important;

    background: $sea-green-10 !important;
    border-color: $sea-green-10 !important;
    color: $black-coral-0 !important;

    box-sizing: border-box;

    font-size: 16px !important;
    line-height: 16px !important;

    &.disabled {
      background-color: $black-coral-2 !important;
      border-color: $black-coral-2 !important;
      cursor: default;

      &:hover {
        background-color: $black-coral-2 !important;
        border-color: $black-coral-2 !important;
        color: $black-coral-0 !important;
      }
    }

    &:hover {
      background-color: $sea-green-12 !important;
      border-color: $sea-green-12 !important;
    }

    &:focus {
      border-color: $sea-green-10 !important;
    }
  }

  &-link {
    &:disabled, &.disabled {
      background: transparent !important;
      border-color: transparent !important;
    }
  }

  &.link-button {
    border-radius: 0 !important;
    color: $sapphire-blue-10 !important;
    text-decoration: underline !important;
    background: transparent !important;
    border: none !important;
    cursor: pointer;
    outline: none !important;

    &.disabled {
      color: $black-coral-10 !important;
      pointer-events: none;
      cursor: default;
    }

    &.danger {
      color: $jasper-10 !important;
    }

    &:hover, &:focus, &:active {
      border-color: $white !important;
      outline: none !important;
    }
  }

  &-sm {
    padding: 5px 25px !important;
    height: 25px;

    font-size: 12px !important;
    line-height: 12px !important;
  }
}

.red-link {
  color: $jasper-10;
  text-decoration: underline;
}
