@import "src/App/variables.scss";

.multi-select {
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    left: -1.36rem;
  }

  .fake-checkbox {
    background: #fff url("../../../assets/images/checkmark.svg") center/75% no-repeat;
    border: #adb5bd solid 1px;
    border-radius: 0.25em;
    height: 18px;
    margin: 0 8px 2px 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 18px;

    &.selected {
      background-color: $sapphire-blue-10;
    }
  }

  .checkbox-label {
    max-width:  calc(100% - 26px);
    margin: 0;
  }
}
