@import "src/App/variables";

.main-header {
  height: 99px;
  width: 100%;

  .logo {
    width: 188px;
    height: 51px;
  }

  .menu-burger {
    background-color: $sapphire-blue-10;

    &.collapsed {
      width: 144px !important;
    }

    &.expanded {
      width: 280px !important;

      .item-text {
        font-size: 18px;
        text-align: left;
      }

      .item-content {
        display: flex;
        float: left;

        img, svg {
          margin-left: 24px;
          margin-right: 16px;
        }
      }
    }

    .item:hover {
      background-color: transparent !important;
    }

    .show-1100 {
      display: none;
    }

    @media screen and (max-width: $menu-breakpoint) {
      &.expanded {
        div, span {
          width: 100%;
          text-align: right;
        }

        .item-content {
          .item-span {
            margin-left: auto !important;
            margin-right: calc(50% - 82px);
          }

          img {
            margin: auto auto auto 10px;
            height: 14px;
            width: 14px;

            &.logo {
              width: 188px;
              height: 51px;
            }
          }
        }

        .show-1100 {
          display: block;
        }
      }
    }
  }

  img {
    &.logo {
      margin-left: 1rem;
    }

    &.official-logo {
      margin-left: 90px;
    }
  }

  @media (max-width: $tablet-last) {
    img {
      &.logo {
        margin-left: calc(50vw - 188px);
      }

      &.official-logo {
        margin-left: 63px !important;
      }

      &.logo-expanded {
        margin-left: calc(50vw - 324px);
      }
    }
  }
  @media (max-width: 1200px) {
    .menu-burger {
      &.collapsed {
        background-color: $white;
      }

      @media screen and (max-width: $menu-breakpoint) {
        &.expanded {
          width: 100% !important;

          .menu-text {
            display: none;
          }
        }
      }
    }
  }

  .mobile {
    margin: 0;
    display: none !important;
  }

  @media (max-width: 1200px) {
    .desktop {
      margin: 0;
      display: none !important;

    }
    .mobile {
      display: block !important;
      color: $sapphire-blue-10;
    }
  }

  .header-menu-right-side {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;

    .header-right-side-separator {
      width: 2px;
      height: 30px;

      background: $gray-lighter;
      border: 1px solid $gray-lighter;

      flex: none;
      align-self: center;
    }

    .logout-button-container {
      margin-top: 24px;
    }

    .language-selection-container {
      flex: none;
      order: 3;
    }

    .role-dropdown-container {
      flex: none;
      order: 2;
    }
  }

  .language-text, .role-header {
    color: $black-coral-10;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  }

  .language-selection {
    display: flex;
    cursor: pointer;
    color: $sapphire-blue-10;
  }
}

.dev {
  background-color: palegreen;
}

.test {
  background-color: yellow;
}
