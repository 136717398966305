@import "../../../variables";

.dashboard-banner {
  .banner-text{
    max-width: 720px;
  }
}

.content-container-card {
  .acknowledgement-row {
    margin: 2.5rem 1.5rem;

    @media screen and (max-width: $tablet-last) {
      margin: 2rem 1.5rem 2.5rem;
    }

    @media screen and (max-width: $mobile-last) {
      margin: 2.5rem 1rem 1.25rem;
    }
  }

  .home-title-margins {
    margin: 1.5rem;

    @media screen and (max-width: $tablet-last) {
      margin: 1.5rem 1.5rem 1rem;
    }

    @media screen and (max-width: $mobile-last) {
      margin: 1.5rem 1rem 1rem;
    }
  }

  .home-title{
    @media screen and (max-width: $mobile-last) {
      font-size: 24px;
    }
  }

  .home-description-margins {
    margin: 1.5rem;



    .home-description-message{
      max-width: 672px;
    }

    @media screen and (max-width: $tablet-last) {
      margin: 1.5rem 1.5rem 1rem;
    }

    @media screen and (max-width: $mobile-last) {
      margin: 1.5rem 1rem 1rem;
    }
  }
}
