@import "src/App/variables";


.officials-login-page-banner {
  background-image: url("../../../assets/images/official_login_page_banner.svg");
  background-size: cover;
  background-position: center;
  height: 100%;

  .banner-cta-card {
    margin-top: 40px;
    margin-bottom: 64px;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;
    border-radius: 5px;
  }
}

.officials-banner-text {
  color: $white;
}
