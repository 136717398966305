@import "src/App/variables.scss";

.renewal-modal.modal-dialog {
  width: 100%;
  max-width: 560px;

  .modal-content {
    width: 100%;
    padding: 24px 24px 32px 24px;

    .modal-header {
      padding: 0;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        margin: 0;
      }
    }

    .modal-body {
      margin-top: 24px;
      padding: 0;

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      label {
        width: 130px;
        margin-right: 10px;
        display: block;
        text-align: right;
      }

      .education-fields {
        margin-top: 18px;


        .single-select, input, .react-datepicker-wrapper {
          width: calc(100% - 140px);
        }

        .css-qmyecx-control {
          padding-left: 0;
        }
      }

      .application-fields {
        margin-left: 140px;

        .file-text-gray {
          max-width: 100%;
          width: auto;
          flex: none;
        }
      }
    }

    .modal-footer {
      padding: 0;

      button {
        margin: 0;
      }

      .btn-primary {
        margin-right: 0;
        margin-left: 24px;
      }

    }
  }
}

@media screen and (max-width: $mobile-last) {
  .renewal-modal.modal-dialog {
    .modal-content {
      .modal-body {
        .education-fields {
          .single-select, input, .react-datepicker-wrapper {
            width: 100%;
          }
        }

        .application-fields {
          margin-left: 0;
        }
      }

      .modal-footer {
        display: block;

        button {
          display: block;
          margin: 0 auto !important;

          &:first-of-type {
            margin: 0 auto 16px auto !important;
          }
        }
      }
    }
  }
}
