@import "../../variables";

.custom-radio-button {
  display: flex;
  align-items: center;

  .radio-button-input {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }

  .custom-content {
    flex-basis: auto;
    flex-grow: 3;
  }
}