@import "src/App/variables";

.card {
  @media screen and (max-width: $mobile-last) {
    dt {
      font-size: 14px;
    }

    dd {
      font-size: 16px;
    }
  }
}

.card.info-card {
  margin-top: 16px;
  background: $black-coral-0 !important;

  .row {
    margin: 0 0 16px 0;

    &:nth-of-type(1) {
      margin-top: 24px;
    }

    &:last-of-type {
      margin-bottom: 24px;
    }

    .col:first-of-type {
      text-align: right;
    }

    .col:nth-of-type(2) {
      font-weight: 500;

      .fw-normal {
        font-weight: normal;
      }

      .file-name {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

@media screen and (max-width: $mobile-last) {
  .card.info-card {
    .row {
      .col:first-of-type {
        text-align: left;
      }

      .col:nth-of-type(2) {
        margin-top: 8px;
      }
    }
  }
}