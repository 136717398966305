@import "src/App/variables";

.react-datepicker {
  @media screen and (max-width: $mobile-last) {
    width: 85%;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  .input-group {
    background: $white;
    margin: 0 !important;
    padding: 0 !important;
    border: 1px solid $black-coral-6 !important;
    border-radius: 4px;
    flex-wrap: nowrap;

    &:focus, &:active, &.open {
      &:not(.disabled) {
        border: 1px solid $sapphire-blue-10 !important;
      }
    }

    &.is-valid {
      border-color: $input-field-valid !important;
    }

    &.is-invalid {
      border-color: $input-field-invalid !important;
    }

    input {
      background-color: transparent;
      border: none !important;
      outline: none !important;
      width: 80%;

      &:focus {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
      }
    }

    img {
      padding-right: 5px;
    }

    svg {
      cursor: pointer;
    }
  }

  .disabled {
    background-color: #E9ECEF;
  }
}

.react-datepicker-popper {
  z-index: 1000;

  @media screen and (max-width: $mobile-last) {
    width: 100%;
  }
}

.react-datepicker__navigation {
  width: 15px;
  height: 24px;
  padding-top: 14px;
  z-index: 1001;

  &.react-datepicker__navigation--previous {
    border: none;
    background: url("../../../assets/images/previous_arrow.svg") no-repeat;
  }

  &.react-datepicker__navigation--next {
    border: none;
    background: url("../../../assets/images/next_arrow.svg") no-repeat;
  }
}

.react-datepicker__month-read-view--selected-month, .react-datepicker__year-read-view--selected-year {
  margin: 0 8px !important;
}

.react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
  border-top-color: $white;
  margin: 2px 2px 0 2px !important;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown {
  background-color: $white;
  color: $black;
  font-size: 14px;
}

.react-datepicker__year-dropdown-container {
  margin: 0 6px;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown,
.react-datepicker__current-month, .react-datepicker__header__dropdown {
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.react-datepicker__header {
  background: $sapphire-blue-10 !important;

  border: 1px solid $black-coral-6 !important;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  height: 50px;
  padding-top: 0;

  .react-datepicker__current-month, .react-datepicker__header__dropdown {
    margin-top: 10px;
    font-size: 18px !important;
    line-height: 27px !important;
    align-items: center !important;
    text-align: center !important;
    color: $white !important;
  }

  .react-datepicker__current-month--hasYearDropdown {
    display: none;
  }

  .react-datepicker__day-names {
    margin-top: 20px;

    .react-datepicker__day-name {
      width: 14.2%;
      margin-right: 0;
      margin-left: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 128%;
    }
  }
}

.react-datepicker__month-container {
  width: 392px;

  @media screen and (max-width: $mobile-last) {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 40px 0 0 0;

    .react-datepicker__day--keyboard-selected {
      background-color: $sapphire-blue-10;
    }

    .react-datepicker__day {
      width: 34px;
      font-size: 14px;
      height: 34px;
      line-height: 34px;
      border-radius: 2rem;
      margin-right: 11px; // (392/7 - 34) / 2
      margin-left: 11px;
      border: none;
      outline: none;
      user-select: none;

      &:not(.react-datepicker__day--disabled):hover {
        background-color: $black-coral-2;
      }

      &.react-datepicker__day--outside-month {
        visibility: hidden;
      }
    }

    @media screen and (max-width: $mobile-last) {
      .react-datepicker__day {
        width: 11%;
        margin-right: 1.5%; // (100%/7 - 11%) / 2
        margin-left: 1.5%;
      }
    }
  }
}
