@import "src/App/variables";

.content-container {
  width: 100%;
  height: 100%;

  .content-container-card {
    margin: 2.5rem 1rem 2.5rem;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;
    border-radius: 5px;

    .content-container-card-large-text {
      font-weight: 300;
      font-size: 32px;
      line-height: 48px;
      color: $base-text-color;
      mix-blend-mode: normal;
      opacity: 0.87;
    }

    .content-container-card-medium-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $base-text-color;
      mix-blend-mode: normal;
      opacity: 0.87;
    }

    .content-container-card-img {
      max-height: 268px;
      width: calc(100% + 16px);
    }

    @media screen and (max-width: 1500px) {
      .content-container-card-img {
        max-height: 220px;
      }
    }
  }
}
