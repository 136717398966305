@import "src/App/variables";

.language-selection-container {
  margin-top: 24px;
}
.language-text {
  color: $black-coral-10;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}

.language-selection {
  display: inline-flex;
  cursor: pointer;
}
.language-option-color {
  color: $sapphire-blue-10;
}

@media screen and (max-width: 1200px) {
  .language-option-color, .language-text {
    color: $white;
  }
}

@media screen and (min-width: 1200px) {
  .language-selection-container {
    order: 3;
  }
}
