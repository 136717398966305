@import "src/App/variables";

#add-new-service-modal {
  max-width: 100%;
  width: 560px;

  .modal-content {
    padding: 24px;

    > * {
      padding: 0;
    }

    .modal-header {
      h4 {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .modal-body {
      margin: 32px 0 40px;

      .selection-wrapper {

        display: grid;
        column-count: 2;
        column-gap: 10px;
        grid-template-columns: 80px auto;
        align-items: center;

        @media screen and (max-width: $mobile-last) {
          grid-template-columns: auto;
          column-count: 1;
        }

        label {
          display: block;
          text-align: right;
          margin: 0;
        }
      }

      .required-services-wrapper {
        margin-top: 24px;

        :first-child {
          padding-bottom: 8px;
        }
      }
    }
  }

  .fw-700 {
    font-weight: 700;
  }
}