@import "src/App/variables";

.attachment-button.btn {
  border-radius: 5px !important;
  min-height: 28px !important;
  font-size: 14px !important;
  font-weight: bold;
  padding: 10px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  white-space: normal;
  text-align: left;
}

.file-text {
  font-size: 14px;
  line-height: 21px;

  .file-name-break {
    word-break: break-all;
  }

  .file-text-gray {
    color: $black-coral-11;
    font-weight: bold;
  }

  .attachment-file-row:not(:last-child) {
    margin-bottom: 11px;
  }
}
