#history-card, #decisions-card {
  padding: 32px 24px 48px 24px;

  h2 {
    margin-bottom: 0;
  }

  .card {
    margin-top: 32px;
  }
}

#application-detail-history-table, #application-decision-table {
  margin: 0 16px;
  width: calc(100% - 32px);
  border: none;

  tbody {
    tr {
      border-left: none;
      border-right: none;
      background: white;

      td:not(:last-child) {
        padding-right: 15px;
      }

      td:first-of-type {
        min-width: 150px;
      }
    }

    tr:last-child {
      border-bottom: none;
    }
  }

  @media screen and (max-width: 768px) {
    thead {
      display: table-header-group;
    }
  }
}

.application-state-fee {
  margin-left: 12px ;
}
