@import "src/App/variables";

.suspension-details {

  button {
    max-width: fit-content;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: normal;
  }

  dl {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
    font-size: 16px;
    line-height: 24px;
    grid-template-columns: 150px auto;
    margin-top: 32px;
  }

  dt {
    font-weight: normal;
    justify-self: end;
  }

  dd {
    font-weight: 500;
    margin: 0;
  }

  .row {
    gap: 16px;
  }
}
