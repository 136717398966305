@import "src/App/variables";

.icon-container {
  display: inline-flex;
  width: 14px;
  height: 14px;
  background-color: white;

  border: 1px solid $black-coral-3;
  border-radius: 2px;

  svg {
    color: $gray-light;
    height: 12px !important;
    width: 12px !important;
  }
}
