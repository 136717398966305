@import "src/App/variables";

.enter-exam-certificate-modal.modal-dialog {
  width: 100%;
  max-width: 560px;
  font-family: Roboto, sans-serif;

  .modal-content {
    width: 100%;
    padding: 24px 24px 32px 24px;

    .modal-header {
      padding: 0;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        margin: 0;
      }
    }

    .modal-body {
      padding: 0;

      .d-block {
        margin-top: 32px;

        .file-text-gray {
          min-width: 150px;
          max-width: 150px;
          text-align: end;
          padding-right: 0;
        }
      }

      div.d-flex {
        height: 40px;
        margin-bottom: 24px;

        label {
          display: flex;
          margin-bottom: 0;
          min-width: 150px;
          font-size: 16px;
          line-height: 24px;
          justify-content: flex-end;
          align-items: center;
          color: $black-coral-18;
          padding-right: 10px;
        }

        .single-select {
          width: 100%;
        }
      }
    }
  }

}
