@import "src/App/variables";

.applicant-container {

  @media (min-width: $desktop-first) {
    .detail-card-grid {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .name-change-title {
    margin-top: 32px;
  }

  @media (max-width: $tablet-last) {
    .name-change-title {
      margin-top: 8px;
    }
  }
}