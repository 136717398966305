body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "./App/variables";
$primary:       $sapphire-blue-10 !default;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !default;
$font-family-monospace: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px // <-- custom here, default 1200
) !default;

@import "~bootstrap/scss/bootstrap";
