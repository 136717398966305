@import "src/App/variables";

.content-container-card {
  .acknowledgement-row {
    margin: 2.5rem 1.5rem;

    @media screen and (max-width: $tablet-last) {
      margin: 2rem 1.5rem 2.5rem;
    }

    @media screen and (max-width: $mobile-last) {
      margin: 2.5rem 1rem 1.25rem;
    }
  }

  .login-title-margins {
    margin: 1.5rem;

    @media screen and (max-width: $tablet-last) {
      margin: 1.5rem 1.5rem 1rem;
    }

    @media screen and (max-width: $mobile-last) {
      margin: 1.5rem 1rem 1rem;
    }
  }
}

.portal-login-page-guide {
  font-size: 1.4rem;
  text-align: center;

  >a {
    color: red;
  }
}