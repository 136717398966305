@import "src/App/variables";

#add-specialist-modal {
  max-width: 595px;
  .modal-header, .subtitle {
    margin-left: 8px;
  }

  .modal-footer {
    margin-right: 6px;
  }

  .subtitle {
    font-family: Roboto sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
  }

  .personal-code-search-box {
    margin: 8px 8px 24px;
    padding: 32px 16px;

    background: $black-coral-0;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;

    .input-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $mobile-last) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .personal-code-input {
      max-width: 175px;
      @media screen and (max-width: $mobile-last) {
        max-width: 100%;
        margin-bottom: 16px;
      }
    }

    .speciality-select {
      width: 320px;
      @media screen and (max-width: $mobile-last) {
        max-width: 100%;
      }
    }
  }

  .modal-body {
    margin: 16px 0 48px;

    p {
      margin-bottom: 8px;
      font-weight: 400;
      size: 16px;
      line-height: 24px;
    }

    .occupation-name-wrapper {
      .occupation-name, .speciality-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      margin-bottom: 16px;
    }

    h5 {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }

    .card {
      background: $black-coral-0;
      border: 1px solid $black-coral-3;
      box-sizing: border-box;
      padding: 16px 24px;

      &.employee-selection {
        display: grid;
        column-count: 2;
        column-gap: 10px;
        grid-template-columns: 80px auto;
        align-items: center;
        margin-bottom: 24px;
      }


      .employee-row {
        display: grid;
        column-count: 2;
        column-gap: 10px;
        grid-template-columns: 15px auto;
        //align-items: center;
      }
    }

    label {
      display: block;
      text-align: right;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}