@import "src/App/variables";

.details-section {
  &__title {
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
    @media screen and (max-width: $mobile-last){
      font-size: 24px;
    }
  }
}
