@import "src/App/variables";

.contact-info-edit-title-text {
  font-size: 24px;
  line-height: 36px;
}

.contact-info-edit-info-text {
  font-size: 16px;
  line-height: 24px;
}

.separator {
  border: 1px solid $black-coral-10;
}

.main-column {
  .form-inline {
    align-items: flex-start;
  }

  .form-group.required .control-label:after {
    font-size: 14px;
    content: "*";
    margin-top: 3px;
    margin-left: 2px;
    color: $jasper-10;
  }

  .link {
    cursor: pointer;
    color: $sapphire-blue-10;
    text-decoration: underline;
  }

  .link:hover {
    text-decoration: none;
  }

  label, .label {
    padding-top: 6px;
    width: 165px;
    justify-content: flex-end !important;
    flex: 0 0 auto;

    &.multi-line-label {
      text-align: right;
      display: block;

      span {
        display: block;
      }
    }
  }

  .zip-code-container, .manual-address-toggle-text-container {
    margin-left: 175px;

    p {
      &.zip-code {
        padding-top: 6px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .input, .clearfix, .input-wrapper {
    margin-left: 10px;
    flex-grow: 1;

    input {
      margin-left: 0;
      width: 100%;
    }
  }

  .phone-container {
    margin-left: 10px;
    flex-grow: 1;

    input[type=tel] {
      flex-grow: 1;
    }
  }

  .QueryBoxInfo {
    margin-left: 20px !important;
  }

  .apartment-select {
    &.apartment-select-error-field {
      width: 162px;
      margin-bottom: -52px;
    }
  }
}

.form-modal {
  z-index: 10; /* keep on top of other elements on the page */
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}


.contact-info-edit-card {
  margin: 5rem 1rem 2.5rem 1rem;
  padding-left: 1rem;
  border: 1px solid $orange-10 !important;
  box-sizing: border-box;
  border-radius: 5px;
  width: 75%;
  height: 148px;

  .contact-info-edit-card-large-text {
    padding-top: 24px;
    font-weight: 300;
    font-size: 32px;
    line-height: 42px;
    color: #34394C;
  }

  .contact-info-edit-card-img {
    height: 268px;
    margin-top: -70px;
    padding-left: 3vw;
  }
}

@media screen and (max-width: 1200px) {
  .contact-info-edit-card {
    margin: 2.5rem 1rem 2.5rem 1rem;
    width: 95%;
    height: 190px;

    .contact-info-edit-card-img {
      height: 150px;
      margin-top: 18px;
      position: relative;
    }
  }
}

.button-container {
  float: right;
}

@media screen and (max-width: 768px) {
  .main-column {
    label {
      &.multi-line-label {
        display: inline;

        span {
          display: inline;
        }
      }
    }
  }
  .contact-info-edit-card {
    .contact-info-edit-card-img {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-last) {
  .button-container {
    float: none;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }
  .main-column {

    label {
      width: 100%;
    }

    .zip-code-container, .manual-address-toggle-text-container {
      margin-left: 0;
    }

    .input, .clearfix, .input-wrapper, .phone-container {
      margin-left: 0;
    }
  }

  label {
    font-size: 14px;
    text-align: left !important;
  }

  .contact-info-edit-card {
    height: 103px !important;
    margin: 6px 6px 2rem 6px;

    .contact-info-edit-card-large-text {
      padding-top: 12px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.phone-code-selector {
  min-width: 100px;
}
