@import "src/App/variables";

#decisions-mobile-wrapper {
  dt {
    font-weight: normal;
    font-size: 14px;
  }
  dd {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}