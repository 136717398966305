@import "src/App/variables";

.activity-license-container {
  padding-top: 40px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
  }

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-top: 24px;
  }

  h5 {
    font-size: 18px;
    line-height: 27px;
    font-style: normal;
    font-weight: 400;
    padding: 24px 0 0 24px;
  }

  p {
    margin-bottom: 0;
  }

  .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .application-title {
    @media screen and (max-width: $mobile-last) {
      display: none;
    }
  }

  .application-footer-buttons {
    white-space: nowrap;

    #saveDraft {
      margin-left: auto !important;
    }

    #forward, #submitConsultationTime {
      margin-left: 0 !important;
      &.service {
        margin-left: auto !important;
      }
    }

    @media screen and (max-width: $mobile-last) {
      display: flex !important;
      flex-direction: column !important;
      align-items: center;
      justify-content: center;
      #saveDraft {
        margin-top: 24px !important;
        margin-left: 0 !important;
        order: 1;
      }

      #discard,
      #back {
        margin-top: 26px !important;
        order: 2;
      }

      #forward {
        margin-top: 16px !important;
        order: 3;
      }
    }
  }

  .info-card {
    min-height: 72px;
  }

  .card {
    background: $white;
    border: 1px solid $black-coral-3;
    box-sizing: border-box;
    border-radius: 5px;
    color: $base-text-color;
  }

  .main-column {
    width: 700px;
    max-width: 100%;
    
    .column-checkbox {
      margin-left: 176px;
    }
  }

  @media screen and (max-width: $tablet-first) {
    .main-column {
      width: auto;

      .btn-primary {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .column-checkbox {
        margin-left: auto;
      }
    }
  }

  .input-wrapper {
    max-width: 525px;

    &.wrapper-sm {
      max-width: 287px;
    }
  }

  .ads-form-group {
    .input-wrapper, .apartment-select-form-group {
      max-width: calc(525px + 16px);
    }
  }

  .custom-control {
    padding-top: 27px;

    label {
      width: auto;
      padding: 0 0 0 11px;
    }
  }

  .business-notification {
    margin-left: 175px;
    color: $jasper-10;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

dd {
  .file-name {
    color: $sapphire-blue-10;
    text-decoration: underline;
    font-weight: normal;
  }
}

.service-details-table-container {
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  table {
    border: none;

    tbody {
      tr {
        border: none;

        td {
          border-top: 1px solid $black-coral-1;

          .service-table-content-row {
            display: grid;
            grid-template-columns: 200px auto;
            grid-column-gap: 16px;
            margin-left: 100px;
          }

          @media screen and (max-width: $tablet-last) {
            .icon-container {
              margin-top: 5px;
            }
          }
        }
      }

      tr.expanded {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
      }

      :last-child {
        td {
          border-bottom: 1px solid $black-coral-1;
        }
      }
    }
  }
}

.official-selection-buttons {
  .radio-button-div {
    margin-bottom: 24px;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.activity-license-details-container {
  .title{
    @media screen and (max-width: $mobile-last) {
      font-size: 28px;
    }
  }

  .details-section-title{
    @media screen and (max-width: $mobile-last) {
      font-size: 24px;
    }
  }

  .location {
    padding-left: 24px;
  }

  .service {
    padding-left: 64px;
  }

  .detail-col {
    .col-header.row {
      padding-left: 48px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: $base-text-color;
      border-top: 1px solid $black-coral-1;
      height: 48px;
      line-height: 48px;
    }

    .row {
      height: auto;
      min-height: 48px;
      margin: 0;
      border-bottom: 1px solid $black-coral-1;

      .col {
        margin-top: auto;
        margin-bottom: auto;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 150%;

        &:first-of-type {
          font-weight: bold;
          text-align: right;
          color: $black-coral-10;
        }

        &:nth-of-type(2) {
          color: $base-text-color;
        }
      }
    }

    .link-button {
      font-size: 14px;
    }
  }

  .detail-col {
    .row {
      .col-md-4 {
        max-width: 250px;
      }
    }
  }

  table {
    td {
      a {
        color: $sapphire-blue-10;
        text-decoration: underline;
      }
    }
  }

  dl {
    width: auto;
    max-width: 690px;
  }
  .location-services-title {
    display: flex;
    justify-content: space-between;
  }
  .invalidate-button {
    max-width: 350px;
  }
}
.activity-license-quick-links {
  ul.quick-links {
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: repeat(3, 30px);
  }
}

.accordion-header.danger {
  border: 1px solid $jasper-10;
  background: $jasper-1;
}
