@import "src/App/variables";

.application-official {
  background-color: $app-background;

  .card-holder {
    margin-left: 204px;
    margin-bottom: 30px;
  }

  .personal-data {
    width: 614px;

    > div {
      justify-content: flex-end;
    }

    .personal-code-country-select {
      max-width: 410px;
    }

    .search-personal-data {
      width: 166px;
      padding: 10px !important;
    }

    .personal-code-input {
      margin-right: 17px;
      max-width: 227px;
    }

    .personal-code-error {
      position: absolute;
      z-index: 10;
      padding-top: 10px;

      .error-field {
        padding: 0 5px;
      }
    }

    label {
      & + div, & + input {
        width: calc(100% - 204px) !important;
      }
    }
  }

  .personal-data-col {
    flex: auto;
    max-width: 614px;
    width: 614px;
    margin-left: 15px;
    padding: 0;

    .form-group {
      justify-content: flex-end;

      .dp-input.disabled {
        .input-group {
          background-color: #e9ecef;
          opacity: 1;

          img, input {
            &:hover {
              cursor: default;
            }
          }
        }
      }
    }
  }

  .main-column {
    width: 614px;
    margin-left: 0 !important;

    label {
      min-width: 196px;
    }

    .form-group {
      label {
        width: 196px;
      }
    }

    .zip-code-container, .manual-address-toggle-text-container {
      margin-left: 206px;
    }
  }

  @media screen and (max-width: $tablet-last) {
    .card-holder {
      margin-left: auto;
      margin-right: auto;
    }
    > h1 {
      margin-bottom: 25px;
    }
    > h1, .stepper, > .card {
      margin-left: 24px !important;
      margin-right: 24px !important;
    }
    .personal-data, .personal-data-col, .main-column {
      width: auto;

      .form-group {
        //justify-content: center;
      }
    }

    .personal-data {
      max-width: 614px;

      > div {
        justify-content: flex-end;

        label {
          width: 150px;
          text-align: right !important;

          & + div, & + input {
            width: calc(100% - 150px) !important;
            max-width: unset !important;
          }
        }

        input {
          width: 410px;
          max-width: unset !important;
        }

        .personal-code-container {
          display: flex;
          justify-content: space-between;

          .personal-code-input {
            width: auto;
            min-width: 130px;
          }

          .search-personal-data {
            margin-left: 10px;
          }
        }

      }
    }

    .personal-data-col {
      margin-left: 15px;
      margin-right: 15px;

      .form-group {
        input, .single-select {
          width: calc(100% - 150px) !important;
        }
      }
    }
    .main-column {
      label {
        min-width: 100px;
        width: 100px;
      }

      .form-group {
        label {
          width: 100px;
        }
      }

      .zip-code-container, .manual-address-toggle-text-container {
        margin-left: 110px;
      }
    }
  }

  .query-box {
    margin-left: auto !important;
  }
}

@media screen and (max-width: $mobile-last) {
  .application-official {

    .main-column {
      .zip-code-container, .manual-address-toggle-text-container {
        margin-left: 0;
      }
    }
  }
}
