@import "src/App/variables";

.error-field {
  min-height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: $jasper-3;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.error-field-text {
  font-weight: bold;
  font-size: 13px;
  padding-top: 7px;
  color: $black-coral-20;
}

.error-field::before {
  content: " ";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  border-bottom: 10px solid $jasper-3;
  margin-left: 5px;
  margin-top: -8px;
}
