@import "src/App/variables.scss";

.role-selection-wrapper {
  font-family: Roboto, sans-serif;
  font-style: normal;
  max-width: 624px;
  width: 100%;
  margin: 40px auto 110px;

  h1 {
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
    color: $base-text-color;
    margin-bottom: 16px;
  }

  .related-companies-header {
    margin-top: 40px;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: $base-text-color;
  }

  .company-cards {
    display: grid;
    grid-row-gap: 16px;
  }

  .card {

    padding: 24px;
    cursor: pointer;

    .role-card-content {
      border-top: 1px solid $black-coral-2;
      padding-top: 16px;
      display: inline-flex;
      justify-content: space-between;

      .name-card-wrapper {
        .role-card-name {
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;

          color: $sapphire-blue-10;
        }

        .role-card-code {
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: $black-coral-10;
        }
      }

      img {
        width: 18px;
      }
    }
  }
}

@media screen and (max-width: $tablet-last) {
  .role-selection-wrapper {
    max-width: 100%;
    width: auto;
    margin: 40px 24px 110px;
  }
}

@media screen and (max-width: $mobile-last) {
  .role-selection-wrapper {
    margin: 40px 16px 64px;
  }
}