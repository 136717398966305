@import "src/App/variables";

:root {
  --width-offset: 0px;
}

.side-menu-column.collapsed + .content-column {
  --width-offset: 145px;
  @media screen and (max-width: 1200px) {
    --width-offset: 0px;
  }
}

.side-menu-column.expanded + .content-column {
  --width-offset: 280px;
  @media screen and (max-width: 990px) {
    --width-offset: 0px;
  }

}

.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header-row {
    min-height: 99px;
    max-height: 10%;
    flex-shrink: 0;
    background-color: $white;
    z-index: 1;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.14);
  }

  .middle-row {
    flex: 1 0 auto;
    flex-wrap: nowrap;

    .side-menu-column {
      background-color: $sapphire-blue-10;
      flex-shrink: 0;

      .btn-link {
        color: $white;
      }

      &.collapsed {
        width: 144px !important;
      }

      &.expanded {
        width: 280px !important;

        .item-text {
          font-size: 18px;
          text-align: left;
        }

        .item-content {
          display: flex;
          float: left;

          img, svg {
            margin-left: 24px;
            margin-right: 16px;
          }
        }
      }

      @media screen and (max-width: $menu-breakpoint) {
        &.expanded {
          width: 100% !important;

          .item-text {
            text-align: center;
          }

          .item-content {
            float: none;
            display: block;

            img, svg {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .content-column {
    background-color: $app-background;
    display: flex;
    flex-direction: column;
    width: calc(100% - var(--width-offset));

    @-moz-document url-prefix() {
      max-width: calc(100% - var(--width-offset));
    }
  }

  .footer-row {
    height: 33%; /*IE Does not like only min-height in percentages*/
    width: 100%;
    flex-shrink: 0;
  }

  @media screen and (max-width: $tablet-last) {
    .footer-row {
      min-height: 639px;
    }
    .header-row {
      min-height: 99px;
    }
  }

  @media screen and (max-width: $menu-breakpoint) {
    .hide-1100 {
      display: none;
    }
    .expand-middle-row {
      height: auto;
    }
    .footer-row {
      min-height: 639px;
    }
    .header-row {
      max-height: 10%;
    }
  }

  @media screen and (max-width: 1200px) {
    .hide-1200 {
      display: none;
    }
  }
}

.substitute-doctor-period-modal {
  max-width: 530px;
}

.proceeding-hidden-button-header {
  text-align: center;
}