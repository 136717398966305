@import "src/App/variables";

#my-applications-container {
  min-height: 500px;
  margin: 40px 16px 0 16px;
  padding-bottom: 40px;

  .row {
    h1 {
      margin-bottom: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-size: 32px;
      line-height: 48px;
    }

    .button-container {
      margin: auto 0 auto auto !important;
    }
  }
  
  .sort-indicator {
    padding-bottom: 3px;
  }

  .danger {
    border: 1px solid $jasper-10;
    background: $jasper-1;
  }
}

@media screen and (max-width: $tablet-last) {
  #my-applications-container {
    thead {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-last) {
  #my-applications-container {
    .row {
      .button-container {
        min-width: 100%;
        margin: 16px auto auto 0 !important;

        button {
          margin-left: 0 !important;
        }
      }
    }

    button {
      margin-right: 20px;
      margin-left: 20px;

      &#deleteApplication {
        order: 2;
        margin-top: 16px !important;
      }

      &#continueWithSavedDraft {
        margin-top: 16px !important;
      }
    }
  }
}
