@import "src/App/variables";

.contact-info-header {
  color: $base-text-color;
  font-size: 32px;
  margin-bottom: 0;
}

.contact-info-card-header {
  color: $base-text-color;
  font-size: 24px;
  line-height: 36px;
}

.change-button-column {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: $mobile-last) {
  .change-button-column {
    flex-direction: row;
  }
}

.contact-info-left-column {
  flex: 0 0 140px !important;

  b {
    float: left;
  }

  @media screen and (min-width: $tablet-first) {
    b {
      float: right;
      white-space: nowrap;
    }
  }
}
