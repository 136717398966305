@import "src/App/variables";

#suspend-activity-license-modal {
  max-width: 100%;
  width: 600px;

  .modal-header {
    padding: 0;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }
  
  .modal-content {
    padding: 24px;

    > * {
      padding: 0;
    }

    .modal-body {
      .label {
        min-width: 155px;
        text-align: right;
        white-space: nowrap;
        color: $black-coral-18;
        margin-right: 10px;
      }

      textarea {
        width: 100%;
        height: 80px;
        background: $white;

        border: 1px solid $black-coral-5;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        color: $black-coral-10;
        &.is-valid {
          border-color: $input-field-valid !important;
        }
        &.is-invalid {
          border-color: $input-field-invalid !important;
        }
      }
    }
  }
}