@import "src/App/variables";

.suspend-health-care-professional-modal.modal-dialog {

  width: 100%;
  max-width: 560px;
  font-family: Roboto, sans-serif;

  .modal-content {
    width: 100%;
    padding: 24px 24px 32px 24px;

    .modal-header {
      padding: 0;

      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 24px;
      }
    }

    .modal-body {
      padding: 0;

      .form-inline {
        margin-bottom: 24px;

        label {
          margin-right: 10px;
          min-width: 150px;
          font-size: 16px;
          line-height: 24px;
          justify-content: flex-end;
          align-items: center;
          color: $black-coral-18;
        }

        .react-datepicker-wrapper, input {
          flex-grow: 1;
        }
      }
    }

    .modal-footer {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding: 24px 0 0 0;

      button {
        box-sizing: border-box;
        line-height: 19px;
        font-size: 16px;
        margin: 0;
      }
    }
  }
}


