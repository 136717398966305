@import "src/App/variables";

.badge-container {
  position: relative;

  .badge {
    padding: 0 10px 0 5px;
    font-size: 13px;
    line-height: 21px;
    white-space: nowrap;

    box-sizing: border-box !important;
    border-radius: 4px !important;
    background: $white;
    margin-right: 20px;

    &.badge-primary {
      border: 2px solid $sapphire-blue-10;
      color: $sapphire-blue-10;


      &.filled {
        background: $sapphire-blue-0;
      }
    }

    &.badge-danger {
      border: 2px solid $jasper-10;
      color: $jasper-10;

      &.filled {
        background: $jasper-0;
      }
    }

    &.badge-warning {
      border: 2px solid $dark-tangerine-10;
      color: $dark-tangerine-15;

      &.filled {
        background: $dark-tangerine-0;
      }
    }

    &.badge-success {
      border: 2px solid $sea-green-10;
      color: $sea-green-10;

      &.filled {
        background: $sea-green-0;
      }
    }
  }

  .badge-icon {
    margin-right: 20px;
    position: absolute;
    transform: translate(50%, -50%);
    right: 0;
    top: 50%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .badge {
    .info-icon {
      margin-right: -19px;
      margin-left: -10px;
    }

    &.badge-success {
      .info-icon {
        margin-right: -23px !important;
      }
    }
  }
}
