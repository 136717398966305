@import "src/App/variables";

.query-box {
  max-width: 409px;
  min-height: 96px;

  margin-left: 175px;
  margin-bottom: 12px;
  box-sizing: border-box;
  border-radius: 5px;


  &.query-box-info {
    background-color: $sapphire-blue-1;
    border: 1px solid $sapphire-blue-10;

    .info-container {
      max-width: 70%;
    }
  }

  &.query-box-success {
    background-color: $sea-green-2;
    border: 1px solid $sea-green-10;

    .info-container {
      min-width: fit-content;
    }
  }

  .query-box-header-text {
    font-size: 18px;
    line-height: 27px;
  }

  .query-box-text {
    font-size: 14px;
    line-height: 21px;
    word-break: break-word;
  }

  .use-button {
    margin-right: 0;
    margin-left: auto;
  }

  .query-box-secondary-button {
    padding: 0 !important;
    background: $black-coral-0 !important;
    color: $black-coral-15 !important;
    padding-top: 2px !important;

    border: 2px solid $black-coral-10 !important;
    box-sizing: border-box;
    border-radius: 20px !important;
    width: 94px;
    height: 25px;

    font-size: 12px !important;

    &:hover {
      background: $sapphire-blue-12 !important;
      color: $black-coral-0 !important;
    }
  }

  // Otherwise icon stretched out in IE
  img {
    align-self: flex-start;

    &.with-padding{
      padding-top: 6px;
    }
  }

}

@media screen and (max-width: $mobile-last) {
  .query-box {
    max-width: 296px;
    margin-left: 0;
    height: 140px;
    flex-direction: column !important;

    &.query-box-info .info-container{
      max-width: 100%;
    }
    .query-box-secondary-button {
      margin-top: -5px !important;
    }
  }
} 
