@import "src/App/variables";

$min-width: 1400px;
.system-administration {
  &-header {
    color: $base-text-color;
    font-size: 32px;
    margin-bottom: 0;
  }

  &-card-header {
    color: $base-text-color;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 20px;
  }

  &-container {
    .nav-tabs {
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .nav-link.active {
        border-color: $black-coral-2 $black-coral-2 $white;
        color: $sapphire-blue-10;
        outline: none;
      }

      .nav-link:hover {
        color: $sapphire-blue-10;
        border-color: transparent;
      }

      .nav-link:focus {
        outline-color: $sapphire-blue-10;
        outline-width: 2px;
      }
    }
  }

  &-form {
    .form-group {
      &.required > label::after {
        content: "*";
        margin-top: 3px;
        margin-left: 2px;
        color: red;
      }

      label {
        margin-right: 12px;
        display: flex;
        width: 110px;
        text-align: right;
      }
    }
  }

  &-card {
    padding: 24px;
  }
}
