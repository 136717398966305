@import "src/App/variables";

#send-to-renewal-modal {
  .modal-content {
    width: 560px;

    .modal-header {
      font-size: 20px;
      line-height: 30px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .modal-body {
      .label {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 130px;
        text-align: right;
        white-space: nowrap;
        color: $black-coral-18;
        margin-right: 10px;
      }

      textarea {
        width: 100%;
        height: 200px;
        background: $white;

        border: 1px solid $black-coral-5;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $black-coral-10;
        &.is-valid {
          border-color: $input-field-valid !important;
        }
        &.is-invalid {
          border-color: $input-field-invalid !important;
        }
      }
    }
  }
}
