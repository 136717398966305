@import "src/App/variables";

.repr-search-label {
  display: flex;
  min-width: 120px;
  align-items: center;
  padding-right: 10px;
}

.repr-search-input,
.repr-search-status {
  min-width: 140px;
  max-width: 275px;
}

.repr-search-date {
  min-width: 140px;
  max-width: 205px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@mixin select {
  >.form-control {
    >div {
      >div {
        display: flex;
        @media (max-width: 410px) {
          font-size: 14px;
        }
      }
    }
  }
}

.repr-search-select {
  min-width: 140px;
  width: 275px;
  @include select;
}

.repr-search-btn {
  line-height: 22px !important;
}

.repr-search-form-container {
  font-size: 16px;
  min-height: 300px;
  border: 1px solid $black-coral-2;
  background-color: $white;
  padding: 28px 12px;
  border-radius: 4px;
}

.repr-search-form {
  margin-bottom: 20px;
}

.repr-form-date-container {
  width: 100%;
  display: flex;

  .dropdown {
    >button {
      padding: 4px !important;
      border-radius: 0 !important;
      height: 100%;
      width: 70px;
      border: 1px solid $black-coral-6 !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
}

.repr-search-dropdown-menu {
  min-width: 70px
}

/* Chrome, Safari, Edge, Opera */
.representative-modal-input-item::-webkit-outer-spin-button,
.representative-modal-input-item::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
.representative-modal-input-item {
  appearance: textfield;
}