@import "src/App/variables";

.alert-container {
  z-index: 2000;
  position: fixed;
  width: 392px;
  max-width: calc(100% - 40px);
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;

  color: $base-text-color;

  .alert-content {
    font-size: 14px;
    color: $base-text-color;
  }

  .close span {
    color: $base-text-color;
  }

  .alert-danger {
    border: 1px solid $jasper-10;
    background: $jasper-1;
  }

  .alert-success {
    border: 1px solid $sea-green-10;
    background: $sea-green-2;
  }

  h5 {
    font-size: 18px;
  }

  .alert {
    margin: 20px 0 0 0;
    box-sizing: border-box;
    border-radius: 5px;

    .alert-heading {
      color: $base-text-color;
    }
  }
}
