@import "src/App/variables";

.ellipsis-loader {
  color: white;
  font: 300 2em/150%;
}

.ellipsis-loader:after {
  content: ' .';
  animation: ellipsis 1s steps(5, end) infinite;
}

@keyframes ellipsis {
  0%, 20% {
    color: $sapphire-blue-10;
    text-shadow: .25em 0 0 $sapphire-blue-10,
    .5em 0 0 $sapphire-blue-10;;
  }
  40% {
    color: white;
    text-shadow: .25em 0 0 $sapphire-blue-10,
    .5em 0 0 $sapphire-blue-10;
  }
  60% {
    text-shadow: .25em 0 0 white,
    .5em 0 0 $sapphire-blue-10;
  }
  80%, 100% {
    text-shadow: .25em 0 0 white,
    .5em 0 0 white;
  }
}
