@import "src/App/variables";

.timeline {
  display: table;
  position: relative;
  &-event {
    display: table-row;
    &-info-wrapper, &-comment, &-icon, &-user {
      display: table-cell;
      vertical-align: middle;
    }

    &-info {
      position: absolute;
      border: dashed $black-coral-2;
      text-align: center;
      line-height: 1;
    }

    &-comment, &-user {
      padding: 8px;
    }

    &-user {
      white-space: nowrap;
      line-height: 24px;
      font-size: 14px;
      img, span {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        margin-right: 8px;
      }
    }

    &-icon {
      position: relative;
      width: 48px;
      height: 48px;
      padding: 48px 8px;
      svg {
        width: 48px !important;
        height: 48px !important;
        padding: 10px;
        vertical-align: middle;
        background: $black-coral-2;
        color: $black-coral-2;
        border-radius: 50%;
        &.done {
          background-color: $sea-green-10;
          color: $white;
        }

        &.in-progress {
          background-color: $sapphire-blue-10;
          color: $sapphire-blue-10;
        }
      }

      &:before {
        content: "";
        position: absolute;
        background: $black-coral-2;

        top: -50%;
        left: 50%;
        margin: 24px 0 0 -1px;
        width: 2px;
        height: 100%;
      }

      &.done:before {
        background-color: $sea-green-10;
      }

      &.in-progress:before {
        background-color: $sapphire-blue-10;
      }
    }

    &:first-child &-icon:before {
      display: none;
    }
  }

  &:first-child:before {
    display: none;
  }

  @media screen and (max-width: $mobile-last) {
    &-event {
      &-comment {
        text-align: right;
        word-spacing: 999px;
      }

      &-info-wrapper {
        position: relative;
        width: 24px;
      }

      &-info {
        top: 72px;
        left: 24px;
        height: 100%;
        width: 24px;
        writing-mode: vertical-lr;
        border-width: 0 0 1px 1px;
        span {
          position: relative;
          bottom: 0;
          left: -24px;
          background: transparent;
          padding: 0;
          font-size: 14px;
        }
      }

      &:first-child &-info {
        border-width: 1px 0 1px 1px;
      }
    }
  }

  @media screen and (min-width: $tablet-first) {
    width: $tablet-first;
    display: flex;
    align-items: flex-start;
    &-event {
      display: flex;
      flex-direction: column-reverse;
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
      &-comment, &-icon, &-user {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-info {
        left: 50%;
        padding-top: 32px;
        position: relative;
        border-width: 0 1px 1px 1px;
        text-align: center;
        line-height: 1;
        margin-left: -1px;
        span {
          display: inline-block;
          position: relative;
          background-color: white;
          padding: 0 8px;
          bottom: -8px;
          left: auto;
          font-size: 16px;
        }
      }

      &-icon {
        width: auto;
        padding: 8px;
        &:before {
          width: 100%;
          height: 2px;
          top: 50%;
          left: -50%;
          margin: -1px auto auto 24px;
        }
      }

      &-user {
        flex-direction: column;
        font-size: 16px;
        img {
          margin: 0;
        }
      }
    }
  }
}