@import "src/App/variables";

.repr-list-header-container {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.representative-list-table {
  border: 1px solid $black-coral-2;
}

.represent-table-action {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  >img {
    cursor: pointer;
    padding-left: 8px;
  }

  > :last-child {
    color: $sapphire-blue-10;
    margin-right: 0;
  }
}

.representative-sort-icon {
  width: 11px !important;
  color: $black-coral-11;
  margin-right: 4px;
}

.representative-info-icon {
  margin-bottom: 2px;
  margin-left: 10px;
}

.representative-modal-container {
  max-width: 600px;
}

.representative-deactivate-modal {
  padding: 18px;
}

.representative-deactivate-modal-footer {
  padding-top: 48px;
}

.representative-container-class {
  margin-top: 0 !important;
}

.representative-add-btn-class {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}