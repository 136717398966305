@import "src/App/variables";

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid $sapphire-blue-10 !important;
    background: $sapphire-blue-10 !important;
    height: 18px;
    width: 18px;
    top: 3px;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    top: 4px;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("../../../assets/images/checkmark.svg") !important;
  }

  .custom-control-label::after {
    background: no-repeat 35% / 75% 75% !important;
  }
}
