@import "src/App/variables";

.service-container {
  .service-title {
    display: flex;
    justify-content: space-between;
  }

  .elements-container {
    max-width: 778px;
    background: $black-coral-0;
    border: 1px solid $black-coral-3;
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;

    ul {
      max-width: 510px;
      padding-left: 12px;
      row-gap: 12px;
      width: 100%;
      list-style-type: none;

      li {
        &.employees-element {
          width: 33%;
          @media screen and (max-width: $tablet-last) {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: $mobile-last) {
      flex-direction: column-reverse;
      padding-top: 14px;

      .input-wrapper {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
    .link-button {
      background: $black-coral-0 !important;
      border-color: $black-coral-0 !important;
    }
  }

  @media screen and (max-width: $mobile-last) {
    .service-title {
      flex-direction: column-reverse;
    }
    h4 {
      padding-top: 6px !important;
    }
  }
}

.red-link {
  line-height: 30px;
}