@import "src/App/variables";

table {
  width: 100%;
  background: #ffffff;
  border: 1px solid $black-coral-2;
  box-sizing: border-box;
  border-radius: 5px;

  thead {
    tr {
      margin-top: 16px;
      margin-bottom: 16px;

      th {
        //padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;

        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        align-items: center;

        color: $black-coral-11;
      }
    }
  }

  tbody {
    background: $black-coral-0;

    tr {
      border: 1px solid $black-coral-2;

      td {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-right: 12px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $base-text-color;
      }

      &.clickable {
        cursor: pointer;
      }

      &.expanded {
        background-color: $black-coral-1;
      }

      &.detail-info {
        background-color: white;
      }
    }
  }

  tfoot {
    tr {
      height: 47px !important;
    }
  }

  .sort {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    position: relative;
    &:before, &:after {
      position: absolute;
      left: 50%;
      margin-left: -8px;
      content: "";
      border: solid $black-coral-3;
      padding: 3px;
      transform: rotate(
          45deg
      );
    }
    &:before {
      border-width: 2px 0 0 2px;
      top: 1px;
    }
    &:after {
      border-width: 0 2px 2px 0;
      bottom: 1px;
    }
  }
  th.asc .sort:before,
  th.desc .sort:after {
    border-color: $black-coral-11;
  }
}

.table {
  thead {
    tr {
      border-width: 1px 0 1px 0;
      th {
        border-width: 0 0 1px 0;
      }
    }
  }

  tbody {
    tr {
      border-width: 1px 0 1px 0;
      &:first-child {
        td {
          border-top-width: 0;
        }
      }
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }
}

.react-table {
  border-collapse: separate;
  border-spacing: 0;
  &-container {
    position: relative;
    @media screen and (min-width: $tablet-first) {
      border: 1px solid $black-coral-2;
      border-radius: 5px;
      overflow: hidden;
    }
    &-square {
      border-radius: 0 !important;
    }
  }

  thead .table-row {
    background: $white;
  }

  .table {
    &-row {
      background: $black-coral-0;
      border: 0;
      border: none;
      transition: background 0.35s ease;
      will-change: background;
      display: block;
      border-top: 1px solid $black-coral-2;
      &:first-child {
        border-top: 0;
      }
      @media screen and (min-width: $tablet-first) {
        display: table-row;
        padding: 0;
        border-top: 0;
      }
      &.expanded {
        background: $black-coral-1;
      }
      &.danger {
        background: $jasper-1;
      }
    }
    &-cell {
      top: auto;
      position: relative;
      background: inherit;
      display: block;
      padding: 6px 1rem;
      min-height: 24px;
      line-height: 24px;
      &.collapse:not(.show) {
        display: none;
      }
      .sort {
        width: 0;
        left: -12px;
      }
      @media screen and (min-width: $tablet-first) {
        border-top: 1px solid $black-coral-2;
        white-space: nowrap;
        display: table-cell;
        min-height: 48px;
        padding: 12px 1.5rem;
        &.sortable {
          padding-right: 1rem;
          padding-left: 2rem;
        }
      }
      &-collapse {
        background: $white;
        padding: 8px 0;
        .table-row {
          background: $white;
        }
      }
      &.sticky {
        & > * {
          visibility: hidden;
        }

        &-absolute {
          position: absolute;
          z-index: 1;
          top: auto;
          left: 0;
          background: inherit;
          text-align: left;
          &:last-child {
            left: auto;
            right: 0;
            text-align: right;
          }
        }
      }
    }
  }

  @media screen and (min-width: $tablet-first) {
    thead {
      .table-row:first-child .table-cell {
        border-top: 0;
      }
    }
  }

  &.shadow {
    .sticky-absolute {
      box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
      &:last-child {
        box-shadow: -4px 0 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

@media screen and (max-width: $mobile-last) {
  thead {
    display: none;
    & + tbody tr:first-child {
      border-top: 0;
    }
  }
}
