@import "src/App/variables";

.sticky-footer {
  min-height: 104px;
  padding: 0 !important;
  background-color: $white;
  box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);

  > * {
    margin: 24px;
  }
}