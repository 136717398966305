@import "src/App/variables";

#documents-info-modal {

  a {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: $sapphire-blue-10;
  }
}